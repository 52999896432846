import React, {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptFormControlSingleSelectBaseProps} from '@compt/types/form/compt-forms';
import comptColors from '@compt/styles/compt-colors';
import {twMerge} from 'tailwind-merge';

export enum ComptDropDownHeaderType {
  BUTTON = 'BUTTON',
}

export interface LabeledDropdownItem {
  id: string | number;
  label: string;
}

export interface ComptDropDownProps<TOptionType, TName = string>
  extends ComptFormControlSingleSelectBaseProps<TOptionType, TName> {
  headerType?: ComptDropDownHeaderType;
  title?: string;
  alignRight?: boolean;
  /**
   * @deprecated use onChange
   * @param selected
   * @param setValue
   */
  onSelect?: (selected: any, setValue?: any) => void;
  /**
   * @deprecated use onChange
   * @param selected
   * @param setValue
   */
  setValue?: UseFormSetValue<FieldValues>;
  ['data-testid']?: string;
  additionalClasses?: string;
  getSecondaryText?: (option: TOptionType) => string;
  heightClass?: string;
  textClass?: string;
  /**
   * Specifies the key to compare by (e.g. email). Useful for when using objects.
   */
  by?: keyof TOptionType | ((a: TOptionType, b: TOptionType) => boolean);
  invalidOptions?: TOptionType[];
}

// eslint-disable-next-line react/function-component-definition
export function ComptDropDown<TOptionType, TName = string>(
  props: ComptDropDownProps<TOptionType, TName>,
) {
  const comparator = (a: TOptionType, b: TOptionType) => {
    if (typeof props.by === 'function') {
      return props.by(a, b);
    }

    if (props.by && a && b) {
      return a[props.by] === b[props.by];
    }

    return a === b;
  };

  const {onSelect, setValue} = props;

  const handleChange = (option: TOptionType) => {
    if (props.onChange) {
      props.onChange(option);
    }

    if (onSelect) {
      onSelect(textClass, props.setValue);
    }
  };

  const heightClass = props.heightClass || 'h-11';
  const textClass = props.textClass || 'text-color-body1';

  const DisplayText = (): string => {
    if (props.value) {
      return props.getDisplayText(props.value);
    }

    return props.placeholder || '';
  };

  return (
    <Listbox
      value={props.value || null}
      onChange={handleChange}
      disabled={props.disabled}
      by={comparator}
    >
      {({open}) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 py-0">
            {props.title}
          </Listbox.Label>
          <div className="relative" dir={props.alignRight ? 'rtl' : 'ltr'}>
            <Listbox.Button
              placeholder={props.placeholder}
              data-testid={props['data-testid']}
              ref={props.inputRef as React.Ref<HTMLButtonElement>}
              dir="ltr"
              className={`
                compt-dropdown flex justify-between relative h-600 ${heightClass}
                font-semibold cursor-default rounded-lg overflow-hidden w-0 min-w-full
                bg-white px-200 py-100 text-left ${textClass} shadow-sm ring-1 ring-inset
                sm:text-sm sm:leading-6 flex flex-row items-center focus:ring-2
                ${
                  props.invalid
                    ? 'ring-stroke-critical focus:ring-stroke-critical focus:ring-inset'
                    : 'ring-stroke-tertiary focus:ring-stroke-focus'
                }
              ${props.additionalClasses}`}
              //  Needed since tailwind bg utility classses aren't applying
              style={{
                backgroundColor: props.disabled ? comptColors.gray['300'] : comptColors.gray['000'],
              }}
            >
              <span
                className={`body1 truncate ${
                  props.placeholder && !props.value ? 'text-gray-400' : 'text-color-body2'
                }`}
              >
                {DisplayText()}
              </span>
              {!props.disabled && (
                <ComptSvgIcon
                  iconName="chevron-down-icon"
                  svgProp={{width: '24px', height: '24px'}}
                />
              )}
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`
                  absolute z-10 mt-1 max-h-60 w-0 min-w-full rounded-md bg-white py-1
                  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
                  overflow-auto
                `}
                dir="ltr"
              >
                {props.options &&
                  props.options.map((item, index) => (
                    <Listbox.Option
                      key={props.getKey(item)}
                      className={({active}) =>
                        twMerge(`
                        relative cursor-default select-none py-2 pl-3 pr-9 ${textClass}
                        ${active ? ' bg-gray-200 cursor-pointer' : ''}
                        `)
                      }
                      value={item}
                      data-testid={`listbox-option-${props.getDisplayText(item)}`}
                    >
                      {({selected}) => (
                        <>
                          <div className="flex flex-row items-baseline">
                            <span
                              className={twMerge(
                                `body3 block truncate compt-dropdown-item ${textClass}`,
                              )}
                            >
                              {props.getDisplayText(item)}
                            </span>
                            {props.getSecondaryText && (
                              <span className="body3 truncate text-color-tertiary pl-2">
                                {props.getSecondaryText(item)}
                              </span>
                            )}
                          </div>
                          {selected ? (
                            <span className={'absolute inset-y-0 right-0 flex items-center pr-4'}>
                              <ComptSvgIcon
                                iconName="check-icon"
                                labelHidden={false}
                                ariaLabel="check-icon"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                {props.invalidOptions &&
                  props.invalidOptions.map((item, index) => (
                    <Listbox.Option
                      key={props.getKey(item)}
                      className={({active}) => 'relative cursor-default select-none py-2 pl-3 pr-9'}
                      value={item}
                      data-testid={`listbox-option-${props.getDisplayText(item)}`}
                      disabled={true}
                    >
                      <div className="flex flex-row items-baseline">
                        <p className="body3 block truncate compt-dropdown-item text-color-tertiary/50">
                          {props.getDisplayText(item)}
                        </p>
                        {props.getSecondaryText && (
                          <p className="body3 text-color-tertiary/50 pl-2">
                            {props.getSecondaryText(item)}
                          </p>
                        )}
                      </div>
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
