import React from 'react';
import _ from 'lodash';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {FieldPathByValue, FieldValues} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';

export interface ComptTextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  secret?: boolean;
  'data-testid'?: string;
  readOnly?: boolean;
}
export const ComptTextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptTextFieldProps<TFieldValues, TName>,
) => {
  const rhfField =
    props.register && props.validation
      ? props.register(props.name, props.validation)
      : props.register
      ? props.register(props.name)
      : null;

  const field = (
    <div className="relative">
      <input
        type={props.secret ? 'password' : 'text'}
        id={_.kebabCase(props.label)}
        placeholder={props.placeholder || ''}
        defaultValue={props.initialValue || ''}
        disabled={props.disabled}
        className={`
          block w-full rounded-md border-0 py-100 px-200 shadow-sm ring-1 ring-inset
          body1 text-color-body2
          placeholder:text-gray-400 sm:leading-6 focus:ring-2
          disabled:bg-surface-disabled disabled:text-disabled-on-light
          ${
            props.errors
              ? 'ring-stroke-critical focus:ring-stroke-critical'
              : 'ring-stroke-tertiary focus:ring-stroke-focus'
          }
        `}
        data-testid={props['data-testid']}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        {...(rhfField || [])}
      />
    </div>
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};
