import React, {Dispatch, SetStateAction, useContext, useEffect} from 'react';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {
  BusinessExpense,
  BusinessExpenseType,
} from '@compt/types/business-expenses/business-expense';
import {useForm} from 'react-hook-form';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useUpdateBusinessExpenseMutation} from '@compt/app/services/api/business-expenses-slice';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {
  EmployeeBusinessExpenseFormController as controller,
  expenseTypeOptions,
} from '../employee-business-expense-form/employee-business-expense-form.controller';
import {StandardBusinessExpenseForm} from '../employee-business-expense-form/standard-business-expense-form';
import {MileageExpenseForm} from '../employee-business-expense-form/mileage-expense-form';
import {PerDiemExpenseForm} from '../employee-business-expense-form/per-diem-expense-form';
import {EmployeeBusinessExpenseFormFieldValues} from '../employee-business-expense-form/employee-business-expense-form.types';
import {BusinessExpensePageContext} from '../../business-expense-page.context';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {CostCenter} from '@compt/types/cost-center';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

type EditBusinessExpenseProps = {
  expenseInfo: BusinessExpense | null;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

export const EditBusinessExpenseForm = (props: EditBusinessExpenseProps) => {
  const {setEditMode, expenseInfo, editMode} = props;
  const {errorRows, setErrorRows} = useContext(BusinessExpensePageContext);
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);

  const initialExpenseType = expenseInfo?.expense_type;

  const sessionQuery = useGetSessionQuery();
  const userId = sessionQuery.data?.user_id;
  const assignedCostCenter = sessionQuery.data?.cost_center;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const company = companyQuery.data;
  const businessConfigs = company?.business_expense_configuration;
  const mileageRate = company?.mileage_rate;

  const formMethods = useForm<EmployeeBusinessExpenseFormFieldValues>();

  const [updateBusinessExpense, {isLoading: isUpdating}] = useUpdateBusinessExpenseMutation();

  const watchedExpenseType = formMethods.watch('expense_type');
  const watchedCostCenter = formMethods.watch('cost_center');
  const watchedExpenseCategory = formMethods.watch('expense_category');

  // Set default values for existing business expense
  useEffect(() => {
    if (!expenseInfo) {
      return;
    }

    const costCenterToAssign = controller.getCostCenterToAssign(
      expenseInfo,
      assignedCostCenter,
      company,
    );

    const expenseCategoryToAssign = controller.getExpenseCategoryToAssign(expenseInfo, company);

    // Assign supporting documents to react-hook-form field names
    const defaultSupportingDocuments: {[key: string]: string} = {};

    expenseInfo.supporting_documents.forEach((doc, index) => {
      const fieldName = `supporting_doc_${index}`;
      defaultSupportingDocuments[fieldName] = doc.document_image;
    });

    formMethods.reset({
      id: expenseInfo.id,
      amount: expenseInfo.amount_of_expense,
      description: expenseInfo.description,
      vendor: expenseInfo.vendor,
      origin: expenseInfo.origin,
      destination: expenseInfo.destination,
      distance: expenseInfo.distance,
      expense_type: controller.getExpenseTypeOption(expenseInfo.expense_type),
      receipt_date: expenseInfo.receipt_date,
      cost_center: costCenterToAssign as CostCenter,
      expense_category: expenseCategoryToAssign,
      receipt_key: expenseInfo.receipt_key,
      start_date: expenseInfo.start_date,
      end_date: expenseInfo.end_date,
      per_diem_rate: expenseInfo.per_diem_rate,
      mileage_rate: company?.mileage_rate,
      ...defaultSupportingDocuments,
    });
  }, [expenseInfo, assignedCostCenter, company, formMethods]);

  useEffect(() => {
    controller.validateCostCenterOnEdit(assignedCostCenter, company, formMethods, expenseInfo);
  }, [expenseInfo, assignedCostCenter, company, formMethods, watchedCostCenter]);

  useEffect(() => {
    controller.validateExpenseCategoryOnEdit(company, formMethods, expenseInfo);
  }, [expenseInfo, company, formMethods, watchedExpenseCategory]);

  function onBusinessExpenseSubmit(form: EmployeeBusinessExpenseFormFieldValues) {
    if (!mileageRate || !expenseInfo) {
      return;
    }

    const adjustedForm = controller.formatExpenseForm(form, mileageRate);
    const supportingDocList = [];

    for (const field in form) {
      if (field.startsWith('supporting_doc_') && !!form[field]) {
        supportingDocList.push(stripImageNameFromS3URL(form[field]));
      }
    }

    const payload = {
      ...adjustedForm,
      id: expenseInfo.id,
      supporting_document_keys: supportingDocList,
    } as BusinessExpense;

    updateBusinessExpense(payload).then((data) => {
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem saving your business expense');
        console.error(data.error);
        return;
      }

      formMethods.reset();
      triggerCustomToast('success', 'Successfully updated business expense');
      setEditMode(false);

      // Remove error from list of errors on successful save
      if (errorRows && errorRows[expenseInfo.id]) {
        const {id} = expenseInfo;
        const {[id]: expenseError, ...restOfErrors} = errorRows;
        setErrorRows(() => restOfErrors);
      }
    });
  }

  if (!companyQuery.data) {
    return null;
  }

  return (
    <>
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col justify-between">
          <form className="grow" onSubmit={formMethods.handleSubmit(onBusinessExpenseSubmit)}>
            <fieldset className="flex h-full grow">
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex justify-center bg-surface-tint sm:overflow-y-clip">
                  <div className="flex flex-col w-full sm:w-[480px] items-end p-6">
                    {expenseInfo && expenseInfo.receipt_image && !props.editMode && (
                      <ComptReceiptDisplayCarousel
                        key={`receipt-display-for-${expenseInfo.id}`}
                        documents={[expenseInfo.receipt_image, ...expenseInfo.supporting_documents]}
                      />
                    )}
                    {props.editMode && (
                      <ComptReceiptFormCarousel
                        formMethods={formMethods}
                        receiptLabel="Uploaded attachments"
                        required={controller.getExpenseReceiptRequired(
                          watchedExpenseType?.id,
                          businessConfigs,
                        )}
                        userId={userId}
                        initialSupportingDocs={expenseInfo?.supporting_documents}
                        initialReceiptValue={expenseInfo?.receipt_image}
                        receiptDomain="business_expenses"
                        supportingDocDomain="be_supporting_document"
                        receiptTestDataId="receipt-key-field"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full grow-[2] p-6">
                  {expenseInfo?.rejection_reason && (
                    <>
                      <div className="flex items-center gap-x-1 mb-1">
                        <ComptSvgIcon iconName="warning-icon" />
                        <label
                          data-testid={'rejection-reason-label'}
                          className="flex items-center body2 text-color-body1"
                        >
                          Rejection reason
                        </label>
                      </div>
                      <div className="bg-surface-secondary-tint p-200 mb-400 rounded-md">
                        <p className="body1">{expenseInfo?.rejection_reason}</p>
                      </div>
                    </>
                  )}
                  <ComptDropDownField
                    name="expense_type"
                    data-testid="expense-type-field"
                    label="Expense type"
                    options={expenseTypeOptions}
                    getKey={(option) => option.id}
                    getDisplayText={(option) => option.label}
                    validation={{required: 'Expense type is required'}}
                    register={formMethods.register}
                    control={formMethods.control}
                    errors={formMethods.formState.errors.expense_type}
                    readOnly={!props.editMode}
                    disabled
                  />
                  {initialExpenseType === BusinessExpenseType.BUSINESS_EXPENSE && (
                    <StandardBusinessExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isEditable={editMode}
                    />
                  )}
                  {initialExpenseType === BusinessExpenseType.MILEAGE && (
                    <MileageExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isEditable={editMode}
                    />
                  )}
                  {initialExpenseType === BusinessExpenseType.PER_DIEM && (
                    <PerDiemExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isEditable={editMode}
                    />
                  )}
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </ComptSidePanel.Content>
      {props.editMode && (
        <ComptSidePanel.Footer>
          <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              size={ComptButtonSize.SMALL}
              disabled={isUpdating || !formMethods.formState.isValid || receiptUploading}
              onClick={formMethods.handleSubmit(onBusinessExpenseSubmit)}
            >
              Save
            </ComptButton>
            <ComptButton
              buttonType={ComptButtonType.BORDERLESS}
              size={ComptButtonSize.SMALL}
              disabled={isUpdating}
              onClick={() => {
                setEditMode(false);
                setReceiptUploading(() => false);
              }}
              className="pl-2"
            >
              Cancel
            </ComptButton>
          </div>
        </ComptSidePanel.Footer>
      )}
    </>
  );
};
