import React, {SetStateAction, useEffect} from 'react';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {FieldValues, useForm} from 'react-hook-form';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';

interface BusinessReportSidePanelProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  onSubmit: (form: FieldValues) => void;
  reportInfo?: BusinessExpenseReport | null;
  mutationLoading: boolean;
}

interface BusinessReportSidePanelValues extends FieldValues {
  title: string;
  description: string;
}

export const BusinessReportSidePanel = ({
  open,
  setOpen,
  reportInfo,
  onSubmit,
  mutationLoading,
}: BusinessReportSidePanelProps) => {
  const formMethods = useForm<BusinessReportSidePanelValues>();

  const {reset, watch} = formMethods;

  useEffect(() => {
    if (!reportInfo) return;
    reset({
      title: reportInfo?.title,
      description: reportInfo?.description,
    });
  }, [reportInfo, reset]);

  const {formState} = formMethods;

  const descriptionPlaceholder =
    // eslint-disable-next-line max-len
    'e.g. I went on an onsite with Acme Corp for 3 days to close a new software deal. (Who, what, when, where, why)';

  return (
    <ComptSidePanel open={open}>
      <ComptSidePanel.Header
        title={`${reportInfo?.id ? 'Edit' : 'Create'} an expense report`}
        setOpen={() => setOpen(false)}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <form>
          <fieldset disabled={formState.isSubmitting}>
            <ComptTextField
              name="title"
              label="Expense report title"
              control={formMethods.control}
              register={formMethods.register}
              validation={{required: 'Title is required'}}
              errors={formState.errors.title}
            />
            <ComptTextAreaField
              name="description"
              label="Description"
              placeholder={descriptionPlaceholder}
              control={formMethods.control}
              register={formMethods.register}
              validation={{required: 'Description is required'}}
              errors={formState.errors.description}
            />
          </fieldset>
        </form>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="grid grid-flow-col gap-3 justify-start">
          <ComptButton
            onClick={formMethods.handleSubmit(onSubmit)}
            type="submit"
            disabled={mutationLoading || !formMethods.formState.isValid}
          >
            {reportInfo?.id ? 'Save' : 'Create'}
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => setOpen(false)}
            disabled={mutationLoading}
          >
            Cancel
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
