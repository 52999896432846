import React, {HTMLAttributes} from 'react';
import {twMerge} from 'tailwind-merge';
import {ComptStyleType} from '@compt/utils/style-helpers';

export enum ComptPillSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface ComptPillProps extends HTMLAttributes<HTMLElement> {
  pillType: ComptStyleType | string;
  pillSize: ComptPillSize;
  className?: string;
  'data-tag'?: string;
}

const defaults: ComptPillProps = {
  pillType: ComptStyleType.PRIMARY,
  pillSize: ComptPillSize.SMALL,
};

const defaultClasses = [
  'compt-pill',
  'inline-flex',
  'items-center',
  'rounded-full',
  'border',
  'px-2',
  'py-0.5',
  'text-center',
];

const getClasses = (props: ComptPillProps) => {
  const classes = [...defaultClasses];
  switch (props.pillType) {
    case ComptStyleType.PRIMARY:
      classes.push(ComptStyleType.PRIMARY);
      break;
    case ComptStyleType.SECONDARY:
      classes.push(ComptStyleType.SECONDARY);
      break;
    case ComptStyleType.GRAY:
      classes.push(ComptStyleType.GRAY);
      break;
    case ComptStyleType.SUCCESS:
      classes.push(ComptStyleType.SUCCESS);
      break;
    case ComptStyleType.DESTRUCTIVE:
      classes.push(ComptStyleType.DESTRUCTIVE);
      break;
    case ComptStyleType.WARNING:
      classes.push(ComptStyleType.WARNING);
      break;
    default:
      if (props.pillType) {
        classes.push(props.pillType);
      }
  }

  switch (props.pillSize) {
    case ComptPillSize.SMALL:
      classes.push('body4');
      break;
    case ComptPillSize.MEDIUM:
      classes.push('px-2.5', 'body3');
      break;
    case ComptPillSize.LARGE:
      classes.push('px-3', 'body3');
      break;
  }

  return classes.join(' ');
};

export const ComptPill = (props: ComptPillProps = defaults) => (
  <span data-tag={props['data-tag']} className={twMerge(getClasses(props), props.className)}>
    {props.children}
  </span>
);

ComptPill.defaultProps = defaults;
