import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {
  BusinessExpense,
  BusinessExpenseType,
  expenseTypeFormats,
} from '@compt/types/business-expenses/business-expense';
import {dateToISODateString} from '@compt/utils/date-helpers';
import {DateTime} from 'luxon';
import {CostCenter} from '@compt/types/cost-center';
import {BusinessExpenseCategory} from '@compt/types/business-expenses/business-expense-category';
import {Company} from '@compt/types/company';
import {BusinessExpenseConfiguration} from '@compt/types/business-expenses/business-expense-configuration';

export type ExpenseTypeOption = {label: string; id: BusinessExpenseType};
export const expenseTypeOptions: ExpenseTypeOption[] = [
  {
    label: expenseTypeFormats[BusinessExpenseType.BUSINESS_EXPENSE],
    id: BusinessExpenseType.BUSINESS_EXPENSE,
  },
  {
    label: expenseTypeFormats[BusinessExpenseType.MILEAGE],
    id: BusinessExpenseType.MILEAGE,
  },
  {
    label: expenseTypeFormats[BusinessExpenseType.PER_DIEM],
    id: BusinessExpenseType.PER_DIEM,
  },
];

export class EmployeeBusinessExpenseFormController {
  static resetFields(
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    defaultCostCenter?: CostCenter,
  ) {
    const assignedCostCenter = defaultCostCenter?.enabled ? defaultCostCenter : undefined;

    formMethods.resetField('cost_center', {defaultValue: assignedCostCenter});
    formMethods.resetField('amount');
    formMethods.resetField('vendor');
    formMethods.resetField('receipt_date');
    formMethods.resetField('start_date', {defaultValue: null});
    formMethods.resetField('end_date', {defaultValue: null});
    formMethods.resetField('origin');
    formMethods.resetField('destination');
    formMethods.resetField('distance');
    formMethods.resetField('receipt_key');
    formMethods.resetField('description');
    formMethods.resetField('per_diem_rate');
  }

  static getExpenseTypeOption(expenseType: string) {
    return expenseTypeOptions.find((type) => type.id === expenseType);
  }

  static getFilteredExpenseCodeOptions = (
    expenseCodeOptions: CostCenter[] | BusinessExpenseCategory[],
    isReview?: boolean,
  ) =>
    expenseCodeOptions.filter(
      (expenseCode) => expenseCode.enabled && (!expenseCode.only_selectable_by_finance || isReview),
    );

  static getExpenseReceiptRequired = (
    expenseType: BusinessExpenseType,
    companyConfig: BusinessExpenseConfiguration | undefined,
  ) => {
    if (!companyConfig) {
      return false;
    }

    const requiredString = 'Receipt is required';
    switch (expenseType) {
      case BusinessExpenseType.BUSINESS_EXPENSE:
        return companyConfig.business_expense_requires_receipt && requiredString;

      case BusinessExpenseType.MILEAGE:
        return companyConfig.mileage_requires_receipt && requiredString;

      case BusinessExpenseType.PER_DIEM:
        return companyConfig.per_diem_requires_receipt && requiredString;

      default:
        return false;
    }
  };

  static formatExpenseForm(
    form: EmployeeBusinessExpenseFormFieldValues,
    mileageRate?: string,
  ): Partial<BusinessExpense> {
    const payloadForm: Partial<BusinessExpense> = {
      expense_type: form.expense_type.id,
      cost_center_id: form.cost_center.id,
      category_id: form.expense_category?.id,
      description: form.description,
      receipt_key: form.receipt_key,
    };

    const expenseType = form['expense_type'];

    if (expenseType.id === BusinessExpenseType.BUSINESS_EXPENSE) {
      payloadForm['amount_of_expense'] = form.amount;
      payloadForm['receipt_date'] = form.receipt_date;
      payloadForm['vendor'] = form.vendor;
    }

    if (expenseType.id === BusinessExpenseType.PER_DIEM) {
      const totalDays = this.calculateTotalDays(form.start_date, form.end_date);
      const totalRate = this.calculateTotalFromRatePerDiem(totalDays, form.per_diem_rate);
      payloadForm['amount_of_expense'] = totalRate.toFixed(2);
      payloadForm['origin'] = form.origin;
      payloadForm['destination'] = form.destination;
      payloadForm['start_date'] = form.start_date;
      payloadForm['end_date'] = form.end_date;
      payloadForm['per_diem_rate'] = form.per_diem_rate;
    }

    if (expenseType.id === BusinessExpenseType.MILEAGE && mileageRate) {
      const formattedDistance = parseFloat(form.distance).toFixed(1);
      payloadForm['distance'] = formattedDistance;

      const totalRate = this.calculateTotalFromRateMileage(formattedDistance, mileageRate);
      payloadForm['amount_of_expense'] = totalRate.toFixed(2);
      payloadForm['origin'] = form.origin;
      payloadForm['destination'] = form.destination;
      payloadForm['start_date'] = form.start_date;
      payloadForm['end_date'] = form.end_date;
    }

    // When deleting the receipt key, need to set to empty string for the API (null value is ignored)
    if (form.receipt_key === null) {
      payloadForm['receipt_key'] = '';
    }
    return payloadForm;
  }

  static calculateTotalFromRatePerDiem(days: number, perDiemRate: number | string) {
    perDiemRate = typeof perDiemRate === 'string' ? parseFloat(perDiemRate) : perDiemRate;

    return days && perDiemRate ? Math.round(days * perDiemRate * 100 + Number.EPSILON) / 100 : 0;
  }

  static calculateTotalDays(
    startDate: Date | string | null | undefined,
    endDate: Date | string | null | undefined,
  ) {
    if (!startDate || !endDate) {
      return 0;
    }
    const startDateAsStr = dateToISODateString(startDate);
    const endDateAsStr = dateToISODateString(endDate);

    const start = DateTime.fromFormat(startDateAsStr, 'yyyy-MM-dd');
    const end = DateTime.fromFormat(endDateAsStr, 'yyyy-MM-dd');
    const totalDays = end.diff(start, 'days').days;

    if (totalDays > 0) {
      return totalDays;
    }

    return 1;
  }

  static calculateTotalFromRateMileage(distance: number | string, mileageRate: number | string) {
    const distanceToUse = typeof distance === 'string' ? parseFloat(distance) : distance;
    const mileageRateToUse =
      typeof mileageRate === 'string' ? parseFloat(mileageRate) : mileageRate;

    return distance && mileageRate
      ? Math.round(distanceToUse * mileageRateToUse * 100 + Number.EPSILON) / 100
      : 0;
  }

  static validateCostCenter = (costCenter: CostCenter) => {
    if (!costCenter) {
      return 'Cost center is required';
    }
    if (!costCenter.enabled) {
      // eslint-disable-next-line max-len
      return 'You do not have a cost center assigned. Please contact your program admin or support@compt.io.';
    }
  };

  static validateCategory = (category: BusinessExpenseCategory) => {
    if (!category) {
      return 'Category is required';
    }

    if (category?.only_selectable_by_finance || !category?.enabled) {
      // eslint-disable-next-line max-len
      return 'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.';
    }
  };

  static getCostCenterToAssign(
    expenseInfo: BusinessExpense | null,
    assignedCostCenter: CostCenter | undefined,
    company: Company | undefined,
  ) {
    const canSelectCostCenter =
      company?.business_expense_configuration?.users_may_select_cost_center;

    if (!canSelectCostCenter && !!assignedCostCenter?.enabled) {
      return assignedCostCenter;
    }

    if (canSelectCostCenter && expenseInfo?.cost_center.enabled) {
      return expenseInfo.cost_center;
    }

    return null;
  }

  static getExpenseCategoryToAssign(
    expenseInfo: BusinessExpense | null,
    company: Company | undefined,
  ) {
    const expenseType = expenseInfo?.expense_type;

    const defaultMileageCategory =
      company?.business_expense_configuration?.default_mileage_expense_category;
    const defaultPerDiemCategory =
      company?.business_expense_configuration?.default_per_diem_expense_category;

    if (expenseType === BusinessExpenseType.MILEAGE && !!defaultMileageCategory) {
      return defaultMileageCategory;
    }

    if (expenseType === BusinessExpenseType.PER_DIEM && !!defaultPerDiemCategory) {
      return defaultPerDiemCategory;
    }

    return expenseInfo?.category.enabled && !expenseInfo?.category.only_selectable_by_finance
      ? expenseInfo.category
      : null;
  }

  static validateCostCenterOnCreate(
    canSelectCostCenter: boolean,
    assignedCostCenter: CostCenter | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
  ) {
    if (!canSelectCostCenter && !assignedCostCenter?.enabled) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'You do not have a cost center assigned. Please contact your program admin or support@compt.io.',
      });
    }
  }

  static validateExpenseCategoryOnCreate(
    defaultCategory: BusinessExpenseCategory | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
  ) {
    if (
      defaultCategory &&
      (!defaultCategory.enabled || defaultCategory?.only_selectable_by_finance)
    ) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.',
      });
    }
  }

  static validateCostCenterOnEdit(
    assignedCostCenter: CostCenter | undefined,
    company: Company | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    expenseInfo?: BusinessExpense | null,
  ) {
    const canSelectCostCenter =
      company?.business_expense_configuration?.users_may_select_cost_center;
    const currentCostCenterValue = formMethods.getValues('cost_center');

    if (canSelectCostCenter && !!currentCostCenterValue) {
      formMethods.clearErrors('cost_center');
      return;
    }

    if (!canSelectCostCenter && !assignedCostCenter?.enabled) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'You do not have a cost center assigned. Please contact your program admin or support@compt.io.',
      });
      return;
    }

    if (canSelectCostCenter && !expenseInfo?.cost_center.enabled) {
      return formMethods.setError('cost_center', {
        type: 'custom',
        message: 'Please select a cost center.',
      });
    }

    if (!canSelectCostCenter && expenseInfo?.cost_center_id !== assignedCostCenter?.id) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Your cost center has changed since you drafted this expense. Please save the expense again to update the cost center.',
      });
      return;
    }
  }

  static validateExpenseCategoryOnEdit(
    company: Company | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    expenseInfo: BusinessExpense | null,
  ) {
    const expenseType = expenseInfo?.expense_type;
    const initialExpenseCategory = expenseInfo?.category;
    const currentExpenseCategoryValue = formMethods.getValues('expense_category');

    const defaultMileageCategory =
      company?.business_expense_configuration?.default_mileage_expense_category;
    const defaultPerDiemCategory =
      company?.business_expense_configuration?.default_per_diem_expense_category;

    const isMileageAndHasDefault =
      expenseType === BusinessExpenseType.MILEAGE && !!defaultMileageCategory;
    const isPerDiemAndHasDefault =
      expenseType === BusinessExpenseType.PER_DIEM && !!defaultPerDiemCategory;

    const unableToSelect =
      (isMileageAndHasDefault &&
        (!defaultMileageCategory?.enabled || defaultMileageCategory?.only_selectable_by_finance)) ||
      (isPerDiemAndHasDefault &&
        (!defaultPerDiemCategory?.enabled || defaultPerDiemCategory?.only_selectable_by_finance));

    const defaultCategoryHasChanged =
      (isMileageAndHasDefault &&
        defaultMileageCategory?.enabled &&
        !defaultMileageCategory?.only_selectable_by_finance &&
        defaultMileageCategory.id !== initialExpenseCategory?.id) ||
      (isPerDiemAndHasDefault &&
        defaultPerDiemCategory?.enabled &&
        !defaultPerDiemCategory?.only_selectable_by_finance &&
        defaultPerDiemCategory.id !== initialExpenseCategory?.id);

    if (
      !!currentExpenseCategoryValue &&
      currentExpenseCategoryValue.enabled &&
      !currentExpenseCategoryValue?.only_selectable_by_finance &&
      !defaultCategoryHasChanged
    ) {
      formMethods.clearErrors('expense_category');
      return;
    }

    if (unableToSelect) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.',
      });
    }

    if (defaultCategoryHasChanged) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Your expense category has changed since you drafted this expense. Please save the expense again to update the category.',
      });
    }

    if (
      (!currentExpenseCategoryValue && !expenseInfo?.category.enabled) ||
      expenseInfo?.category.only_selectable_by_finance
    ) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message: 'Please select an expense category.',
      });
    }
  }
}
