import React from 'react';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

interface ComptFeatureProps {
  featureFlag: FeatureFlags;
  children: React.ReactElement;
}

/**
 * Renders the children only if the given feature is enabled.
 * @param featureFlag
 * @param children
 * @see featureEnabled
 * @example
 * Usage:
 * In the following example, the child, <p>International is enabled.</p>
 * will be rendered only if the feature is enabled.
 * ```
 * <ComptFeature featureFlag={FeatureFlags.INTERNATIONAL}>
 *   <p>International is enabled.</p>
 * </ComptFeature>
 * ```
 */
export const ComptFeature = ({featureFlag, children}: ComptFeatureProps) =>
  featureEnabled(featureFlag) ? children : null;
