import React, {useEffect, useState} from 'react';
// Types
import {Swiper as SwiperCore} from 'swiper/types';
import {SupportingDocument} from '@compt/types/supporting-document';

// UI components
import {ComptCarousel} from '../compt-carousel/compt-carousel';
import {ComptCarouselNav} from '../compt-carousel/compt-carousel-nav';
import {SwiperSlide} from 'swiper/react';
import {ComptUploadedReceipt} from '../compt-uploaded-receipt/compt-uploaded-receipt';
import {ComptReceiptPagination} from './compt-receipt-pagination';

interface ComptReceiptDisplayCarouselProps {
  documents?: Array<SupportingDocument | string>;
}

export const ComptReceiptDisplayCarousel = (props: ComptReceiptDisplayCarouselProps) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const {documents} = props;

  // Reset swiper position with new documents
  useEffect(() => {
    if (documents) {
      slideTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    if (swiperRef) {
      swiperRef.on('slideChange', () => {
        setActiveIndex(swiperRef.activeIndex);
      });
    }
  }, [swiperRef]);

  function slideTo(index: number) {
    if (swiperRef) {
      swiperRef?.slideTo(index, 0);
      setActiveIndex(() => index);
    }
  }

  return (
    <div className="flex flex-col w-full h-full items-center">
      <div className="compt-slider-container flex flex-col sm:w-full items-end">
        <ComptCarousel className="compt-slider-carousel mb-4" setSwiperRef={setSwiperRef}>
          <ComptCarouselNav numberOfSlides={props.documents?.length ?? 0} errors={false} />
          {props.documents?.map((doc, index) => {
            const docImage = typeof doc === 'string' ? doc : doc.document_image;
            const label = index === 0 ? 'Uploaded receipt' : 'Uploaded attachments';

            return (
              <SwiperSlide key={`doc-${index}`}>
                <div className="flex flex-col h-full w-full">
                  <label className="flex items-center body2 text-gray-700 mb-1">{label}</label>
                  <ComptUploadedReceipt receiptImage={`${docImage}`} className="h-[548px]" />
                </div>
              </SwiperSlide>
            );
          })}
        </ComptCarousel>
      </div>
      <div className="flex justify-start w-full">
        {props.documents && props.documents.length > 1 && (
          <ComptReceiptPagination
            paginationLength={props.documents.length}
            activeIndex={activeIndex}
            slideTo={slideTo}
          />
        )}
      </div>
    </div>
  );
};
