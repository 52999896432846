import React, {CSSProperties, useState} from 'react';

interface MagnifierProps {
  src: string;
  imgStyle: object;
  magnifierHeight?: number;
  magnifierWidth?: number;
  zoomLevel?: number;
  alt?: string;
  className?: string;
}

export const ComptMagnifier = ({
  src,
  imgStyle,
  magnifierHeight = 300,
  magnifierWidth = 300,
  zoomLevel = 2,
  className,
}: MagnifierProps) => {
  const [coords, setCoords] = useState({x: 0, y: 0});
  const [size, setSize] = useState({width: 0, height: 0});
  const [showMagnifier, setShowMagnifier] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement>) => {
    const {width, height} = e.currentTarget.getBoundingClientRect();
    setSize({width, height});
    setShowMagnifier(true);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    const {top, left} = e.currentTarget.getBoundingClientRect();
    setCoords({
      x: e.pageX - left - window.scrollX,
      y: e.pageY - top - window.scrollY,
    });
  };

  const handleMouseLeave = () => {
    setShowMagnifier(false);
  };

  const magnifierStyle: CSSProperties = {
    display: showMagnifier ? 'block' : 'none',
    position: 'absolute',
    pointerEvents: 'none',
    height: magnifierHeight,
    width: magnifierWidth,
    border: '2px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '50%',
    backgroundImage: `url('${src}')`,
    backgroundRepeat: 'no-repeat',
    top: coords.y - magnifierHeight / 2,
    left: coords.x - magnifierWidth / 2,
    backgroundSize: `${size.width * zoomLevel}px ${size.height * zoomLevel}px`,
    backgroundPosition: `${-coords.x * zoomLevel + magnifierWidth / 2}px ${
      -coords.y * zoomLevel + magnifierHeight / 2
    }px`,
  };

  return (
    <div className={className} style={{position: 'relative', display: 'inline-block'}}>
      <img
        src={src}
        style={imgStyle}
        alt="img"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
      <div style={magnifierStyle} />
    </div>
  );
};
