import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import React, {useEffect, useState} from 'react';
import {ComptLayoutController} from '../compt-layout/compt-layout.controller';
import {SideNavContext} from './side-nav-context';
import {ComptSideNav} from './compt-side-nav';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButtonIcon} from '../compt-button/compt-button';
import comptColors from '@compt/styles/compt-colors';

export const ComptSideNavAdmin = () => {
  const controller = new ComptLayoutController();
  const session = useGetSessionQuery();

  const isAdminView = true;
  const isMobile = useIsMobileView();
  const defaultSidebarOpen = isMobile ? false : true;

  const [sidebarOpen, setSidebarOpen] = useState(defaultSidebarOpen);
  const [stipendAdminOpen, setStipendAdminOpen] = useState(false);
  const [expenseAdminOpen, setExpenseAdminOpen] = useState(false);
  const [internalAdminOpen, setInternalAdminOpen] = useState(false);

  const eligibleNavSections = controller.getEligibleNavSectionsAndItems(session.data, true);

  // Adjust the margin of the content container in Django template when side nav is open or closed.
  useEffect(() => {
    if (isMobile) return;

    const contentContainer = document.getElementById('nds-content-container');
    if (sidebarOpen && contentContainer) {
      contentContainer.style.transition = 'margin-left 300ms ease-in-out';
      contentContainer.style.marginLeft = '300px';
      return;
    }

    if (!sidebarOpen && contentContainer) {
      contentContainer.style.transition = 'margin-left 300ms ease-in-out 100ms';
      contentContainer.style.marginLeft = '90px';
      return;
    }
  }, [sidebarOpen, isMobile]);

  if (!eligibleNavSections) {
    return null;
  }

  return (
    <SideNavContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        stipendAdminOpen,
        setStipendAdminOpen,
        expenseAdminOpen,
        setExpenseAdminOpen,
        internalAdminOpen,
        setInternalAdminOpen,
        isAdminView,
      }}
    >
      {isMobile && !sidebarOpen && (
        <button
          className="mr-200 fixed top-5 left-2 nds-open-menu-button"
          onClick={() => setSidebarOpen((previousState) => !previousState)}
          style={{
            zIndex: 900,
          }}
        >
          <ComptSvgIcon iconName={ComptButtonIcon.MENU} />
        </button>
      )}
      {isMobile && sidebarOpen && (
        <button
          className="mr-200 fixed top-5 right-2 nds-close-menu-button"
          onClick={() => setSidebarOpen((previousState) => !previousState)}
          style={{
            zIndex: 900,
          }}
        >
          <ComptSvgIcon iconName={ComptButtonIcon.X} />
        </button>
      )}
      <div
        className={'fixed sm:flex left-0 top-12 bottom-0 admin-side-nav border-t mt-[11px]'}
        style={{borderTop: `1px solid ${comptColors.gray['200']}`}}
      >
        <ComptSideNav navSections={eligibleNavSections} />
      </div>
    </SideNavContext.Provider>
  );
};
