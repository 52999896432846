import React, {ReactElement} from 'react';
import {FilterBarProps, ChangeValueTypes, FilterConfigType} from './compt-filter-bar.types';
import {ComptFilterBarController} from './compt-filter-bar.controller';

export const ComptFilterBar = (props: FilterBarProps) => {
  const {filterConfig, customCollapsibleFilters} = props;

  const {getFilterValues, handleFilterChange} = new ComptFilterBarController(props);

  const collapsibleComponentList: ReactElement[] = [];
  const nonCollapsibleComponentList: ReactElement[] = [];

  const keys: (keyof FilterConfigType)[] = Object.keys(filterConfig) as (keyof FilterConfigType)[];

  keys.forEach((key: keyof FilterConfigType) => {
    const obj = filterConfig[key];
    if (!obj) {
      return;
    }

    if (!obj.ignore) {
      if (obj.nonCollapsible) {
        nonCollapsibleComponentList.push(
          <obj.filterType
            label={obj.label}
            currentValues={getFilterValues(key)}
            options={obj.options}
            key={key}
            handleChange={(changeValue: ChangeValueTypes) => handleFilterChange(key, changeValue)}
          />,
        );
      } else {
        collapsibleComponentList.push(
          <div key={key} className="col-auto">
            <div className="filter-container">
              <obj.filterType
                label={obj.label}
                currentValues={getFilterValues(key)}
                options={obj.options}
                handleChange={(changeValue: ChangeValueTypes) =>
                  handleFilterChange(key, changeValue)
                }
                filterState={obj}
              />
            </div>
          </div>,
        );
      }
    }
  });

  /* Handles components that have custom collapsible styling */
  if (customCollapsibleFilters) {
    customCollapsibleFilters.forEach((filter, index) => {
      collapsibleComponentList.push(
        <div key={index} className="col-auto">
          {filter}
        </div>,
      );
    });
  }

  return (
    <div className="flex justify-between py-3 px-4 border-b">
      {nonCollapsibleComponentList && (
        <div className="sm:w-1/2 self-center">{nonCollapsibleComponentList}</div>
      )}
      {collapsibleComponentList && (
        <div className="col align-self-center">
          <div className="flex-1 justify-end">{collapsibleComponentList}</div>
        </div>
      )}
    </div>
  );
};
