import React from 'react';
import {ReactComponent as BarChartIconGray} from '../../../assets/images/icons/bar-chart-icon-gray.svg';
import {ReactComponent as BarChartIconWhite} from '../../../assets/images/icons/bar-chart-icon-white.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/images/icons/calendar-icon.svg';
import {ReactComponent as CheckDoneIconGray} from '../../../assets/images/icons/check-done-icon-gray.svg';
import {ReactComponent as CheckDoneIconBold} from '../../../assets/images/icons/check-done-icon-bold.svg';
import {ReactComponent as ChevronLeftIcon} from '../../../assets/images/icons/chevron-left-icon.svg';
import {ReactComponent as ChevronLeftDoubleIcon} from '../../../assets/images/icons/chevron-left-double-icon.svg';
import {ReactComponent as ChevronRightIcon} from '../../../assets/images/icons/chevron-right-icon.svg';
import {ReactComponent as ChevronRightIconGray} from '../../../assets/images/icons/chevron-right-icon-gray.svg';
import {ReactComponent as ChevronRightIconGray700} from '../../../assets/images/icons/chevron-right-icon-gray-700.svg';
import {ReactComponent as ChevronRightDoubleIcon} from '../../../assets/images/icons/chevron-right-double-icon.svg';
import {ReactComponent as ChevronDownIcon} from '../../../assets/images/icons/chevron-down-icon.svg';
import {ReactComponent as GoogleIcon} from '../../../assets/images/icons/google-icon.svg';
import {ReactComponent as SlackIcon} from '../../../assets/images/icons/slack-icon.svg';
import {ReactComponent as HomeIconGray} from '../../../assets/images/icons/home-icon-gray.svg';
import {ReactComponent as HomeIconWhite} from '../../../assets/images/icons/home-icon-white.svg';
import {ReactComponent as LayersIconGray} from '../../../assets/images/icons/layers-icon-gray.svg';
import {ReactComponent as EyeIcon} from '../../../assets/images/icons/eye-icon.svg';
import {ReactComponent as LayersIconWhite} from '../../../assets/images/icons/layers-icon-white.svg';
import {ReactComponent as NarrowArrowRightIcon} from '../../../assets/images/icons/arrow-narrow-right.svg';
import {ReactComponent as PieChartIconGray} from '../../../assets/images/icons/pie-chart-icon-gray.svg';
import {ReactComponent as PieChartIconWhite} from '../../../assets/images/icons/pie-chart-icon-white.svg';
import {ReactComponent as PlusIcon} from '../../../assets/images/icons/plus-icon.svg';
import {ReactComponent as PlusIconGray} from '../../../assets/images/icons/plus-icon-gray.svg';
import {ReactComponent as PlusIconBlue} from '../../../assets/images/icons/plus-icon-blue.svg';
import {ReactComponent as RedTrashIcon} from '../../../assets/images/icons/red-trash-icon.svg';
import {ReactComponent as TrashIcon} from '../../../assets/images/icons/trash-icon.svg';
import {ReactComponent as TrashIconWhite} from '../../../assets/images/icons/trash-icon-white.svg';
import {ReactComponent as DocumentIcon} from '../../../assets/images/icons/document-icon.svg';
import {ReactComponent as UploadReceiptIcon} from '../../../assets/images/icons/upload-receipt-icon.svg';
import {ReactComponent as XIcon} from '../../../assets/images/icons/x-icon.svg';
import {ReactComponent as XIconStyleable} from '../../../assets/images/icons/x-icon-styleable.svg';
import {ReactComponent as PencilIcon} from '../../../assets/images/icons/pencil-icon.svg';
import {ReactComponent as AlertCircleIcon} from '../../../assets/images/icons/alert-circle-icon.svg';
import {ReactComponent as AlertCircleBlueIcon} from '../../../assets/images/icons/alert-circle-blue-icon.svg';
import {ReactComponent as AlertCircleGrayIcon} from '../../../assets/images/icons/alert-circle-gray-icon.svg';
import {ReactComponent as AlertCircleWarningIcon} from '../../../assets/images/icons/alert-circle-warning-icon.svg';
import {ReactComponent as AlertTriangleWarningIcon} from '../../../assets/images/icons/alert-triangle-warning-icon.svg';
import {ReactComponent as AlertCircleQuestionBlueIcon} from '../../../assets/images/icons/alert-circle-question-blue-icon.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/images/icons/arrow-right.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../assets/images/icons/arrow-left.svg';
import {ReactComponent as PlusCircle} from '../../../assets/images/icons/plus-circle.svg';
import {ReactComponent as MinusCircle} from '../../../assets/images/icons/minus-circle.svg';
import {ReactComponent as MagnifyingGlass} from '../../../assets/images/icons/magnifying-glass.svg';
import {ReactComponent as HelpCircle} from '../../../assets/images/icons/help-circle.svg';
import {ReactComponent as SaveIcon} from '../../../assets/images/icons/save-icon.svg';
import {ReactComponent as CheckIcon} from '../../../assets/images/icons/check-icon.svg';
import {ReactComponent as CircleCheckIcon} from '../../../assets/images/icons/circle-check-icon.svg';
import {ReactComponent as CircleCheckGreenIcon} from '../../../assets/images/icons/circle-check-green-icon.svg';
import {ReactComponent as CircleCheckGreenBorderIcon} from '../../../assets/images/icons/circle-check-green-border-icon.svg';
import {ReactComponent as DocumentFailedIcon} from '../../../assets/images/icons/document-failed-icon.svg';
import {ReactComponent as GearIconGray} from '../../../assets/images/icons/gear-icon-gray.svg';
import {ReactComponent as LogOutIcon} from '../../../assets/images/icons/log-out-icon.svg';
import {ReactComponent as CoinStackedIconGray} from '../../../assets/images/icons/coin-stacked-icon-gray.svg';
import {ReactComponent as CoinStackedIconWhite} from '../../../assets/images/icons/coin-stacked-icon-white.svg';
import {ReactComponent as WalletIconGray} from '../../../assets/images/icons/wallet-icon-gray.svg';
import {ReactComponent as WalletIconWhite} from '../../../assets/images/icons/wallet-icon-white.svg';
import {ReactComponent as BankNoteIconGray} from '../../../assets/images/icons/bank-note-icon-gray.svg';
import {ReactComponent as BankNoteIconBold} from '../../../assets/images/icons/bank-note-icon-bold.svg';
import {ReactComponent as CoinSwapIconGray} from '../../../assets/images/icons/coin-swap-icon-gray.svg';
import {ReactComponent as CoinSwapIconWhite} from '../../../assets/images/icons/coin-swap-icon-white.svg';
import {ReactComponent as ActivityIconGray} from '../../../assets/images/icons/activity-icon-gray.svg';
import {ReactComponent as ActivityIconBold} from '../../../assets/images/icons/activity-icon-bold.svg';
import {ReactComponent as AwardIconGray} from '../../../assets/images/icons/award-icon-gray.svg';
import {ReactComponent as AwardIconBold} from '../../../assets/images/icons/award-icon-bold.svg';
import {ReactComponent as AnnotationQuestionIconGray} from '../../../assets/images/icons/annotation-question-icon-gray.svg';
import {ReactComponent as AnnotationQuestionIconBold} from '../../../assets/images/icons/annotation-question-icon-bold.svg';
import {ReactComponent as BriefcaseIconGray} from '../../../assets/images/icons/briefcase-icon-gray.svg';
import {ReactComponent as BriefcaseIconBold} from '../../../assets/images/icons/briefcase-icon-bold.svg';
import {ReactComponent as SearchRefractionIconGray} from '../../../assets/images/icons/search-refraction-gray.svg';
import {ReactComponent as SearchRefractionIconWhite} from '../../../assets/images/icons/search-refraction-white.svg';
import {ReactComponent as SettingsIconGray} from '../../../assets/images/icons/settings-icon-gray.svg';
import {ReactComponent as SettingsIconWhite} from '../../../assets/images/icons/settings-icon-white.svg';
import {ReactComponent as UsersIconGray} from '../../../assets/images/icons/users-icon-gray.svg';
import {ReactComponent as UsersIconWhite} from '../../../assets/images/icons/users-icon-white.svg';
import {ReactComponent as MenuIcon} from '../../../assets/images/icons/menu-icon.svg';
import {ReactComponent as AlertCircleBlackIcon} from '../../../assets/images/icons/alert-circle-black-icon.svg';
import {ReactComponent as Error404} from '../../../assets/images/icons/error-404.svg';
import {ReactComponent as RedCircleTrashIcon} from '../../../assets/images/icons/red-circle-trash-icon.svg';
import {ReactComponent as YellowFolderIcon} from '../../../assets/images/icons/yellow-folder-icon.svg';
import {ReactComponent as FileQuestionIcon} from '../../../assets/images/icons/file-question-icon.svg';
import {ReactComponent as ExpirationIndicator0} from '../../../assets/images/icons/expiration-indicator-0.svg';
import {ReactComponent as ExpirationIndicator50} from '../../../assets/images/icons/expiration-indicator-50.svg';
import {ReactComponent as ExpirationIndicator75} from '../../../assets/images/icons/expiration-indicator-75.svg';
import {ReactComponent as ExpirationIndicator95} from '../../../assets/images/icons/expiration-indicator-95.svg';
import {ReactComponent as FeaturedIcon} from '../../../assets/images/icons/featured-icon.svg';
import {ReactComponent as ArrowCircleBrokenLeft} from '../../../assets/images/icons/arrow-circle-broken-left.svg';
import {ReactComponent as ArrowCircleBrokenRight} from '../../../assets/images/icons/arrow-circle-broken-right.svg';
import {ReactComponent as StarIcon} from '../../../assets/images/icons/star-icon.svg';
import {ReactComponent as BlueStarIcon} from '../../../assets/images/icons/star-icon-blue.svg';
import {ReactComponent as MegaphoneIcon} from '../../../assets/images/icons/megaphone-icon.svg';
import {ReactComponent as MegaphoneIconWhite} from '../../../assets/images/icons/megaphone-icon-white.svg';
import {ReactComponent as CheckVerifiedIcon} from '../../../assets/images/icons/check-verified.svg';
import {ReactComponent as ReceiptIcon} from '../../../assets/images/icons/receipt-icon.svg';
import {ReactComponent as FilterLinesBlueIcon} from '../../../assets/images/icons/filter-lines-blue-icon.svg';
import {ReactComponent as EllipseVerticalIcon} from '../../../assets/images/icons/ellipse-vertical-icon.svg';
import {ReactComponent as EmptyImageIcon} from '../../../assets/images/icons/empty-img.svg';
import {ReactComponent as WarningIcon} from '../../../assets/images/icons/warning-icon.svg';
// Category
import {ReactComponent as AnniversaryBonusIcon} from '../../../assets/images/icons/categories/anniversary-bonus.svg';
import {ReactComponent as BusinessTravelIcon} from '../../../assets/images/icons/categories/business-travel.svg';
import {ReactComponent as CaregivingIcon} from '../../../assets/images/icons/categories/caregiving.svg';
import {ReactComponent as CharitableGivingIcon} from '../../../assets/images/icons/categories/charitable-giving.svg';
import {ReactComponent as CellphoneIcon} from '../../../assets/images/icons/categories/cellphone.svg';
import {ReactComponent as CommuterParkingIcon} from '../../../assets/images/icons/categories/commuter-parking.svg';
import {ReactComponent as CommuterTransitIcon} from '../../../assets/images/icons/categories/commuter-transit.svg';
import {ReactComponent as CoworkingIcon} from '../../../assets/images/icons/categories/coworking.svg';
import {ReactComponent as EntertainmentIcon} from '../../../assets/images/icons/categories/entertainment.svg';
import {ReactComponent as ExperiencesIcon} from '../../../assets/images/icons/categories/experiences.svg';
import {ReactComponent as FamilyIcon} from '../../../assets/images/icons/categories/family.svg';
import {ReactComponent as FoodIcon} from '../../../assets/images/icons/categories/food.svg';
import {ReactComponent as FinancialWellnessIcon} from '../../../assets/images/icons/categories/financial-wellness.svg';
import {ReactComponent as HomeIcon} from '../../../assets/images/icons/categories/home.svg';
import {ReactComponent as InternetIcon} from '../../../assets/images/icons/categories/internet.svg';
import {ReactComponent as PersonalDevelopmentIcon} from '../../../assets/images/icons/categories/personal-development.svg';
import {ReactComponent as PersonalTravelIcon} from '../../../assets/images/icons/categories/personal-travel.svg';
import {ReactComponent as PetsIcon} from '../../../assets/images/icons/categories/pets.svg';
import {ReactComponent as ProductivityIcon} from '../../../assets/images/icons/categories/productivity.svg';
import {ReactComponent as ProfessionalCertificationsIcon} from '../../../assets/images/icons/categories/professional-certifications.svg';
import {ReactComponent as ProfessionalDevelopmentIcon} from '../../../assets/images/icons/categories/professional-development.svg';
import {ReactComponent as RemoteEquipmentIcon} from '../../../assets/images/icons/categories/remote-office-work.svg';
import {ReactComponent as SafetyEquipmentIcon} from '../../../assets/images/icons/categories/safety-equipment.svg';
import {ReactComponent as StudentLoansIcon} from '../../../assets/images/icons/categories/student-loans.svg';
import {ReactComponent as TeamEventsIcon} from '../../../assets/images/icons/categories/team-events.svg';
import {ReactComponent as TechIcon} from '../../../assets/images/icons/categories/tech.svg';
import {ReactComponent as TreatYourselfIcon} from '../../../assets/images/icons/categories/treat-yourself.svg';
import {ReactComponent as WellnessIcon} from '../../../assets/images/icons/categories/health-and-wellness.svg';
// Employee Account Settings
import {ReactComponent as FileLockIcon} from '../../../assets/images/icons/file-lock-icon.svg';
import {ReactComponent as SettingsSliderIcon} from '../../../assets/images/icons/settings-slider.svg';
import {ReactComponent as FileIcon} from '../../../assets/images/icons/file-icon.svg';
import {ReactComponent as FileIconBlue} from '../../../assets/images/icons/file-icon-blue.svg';
import {ReactComponent as UserIcon} from '../../../assets/images/icons/user-icon.svg';
import {ReactComponent as PasswordLockIcon} from '../../../assets/images/icons/password-lock-icon.svg';
import {ReactComponent as UploadIcon} from '../../../assets/images/icons/upload-icon.svg';
import {ReactComponent as FilePdfIcon} from '../../../assets/images/icons/file-pdf-icon.svg';
import {ReactComponent as FilePngIcon} from '../../../assets/images/icons/file-png-icon.svg';
import {ReactComponent as FileEpsIcon} from '../../../assets/images/icons/file-eps-icon.svg';
import {ReactComponent as FileSvgIcon} from '../../../assets/images/icons/file-svg-icon.svg';
import {ReactComponent as FileGifIcon} from '../../../assets/images/icons/file-gif-icon.svg';
import {ReactComponent as FileTiffIcon} from '../../../assets/images/icons/file-tiff-icon.svg';
import {ReactComponent as FileWebpIcon} from '../../../assets/images/icons/file-webp-icon.svg';
import {ReactComponent as FileJpegIcon} from '../../../assets/images/icons/file-jpeg-icon.svg';
import {ReactComponent as FileJpgIcon} from '../../../assets/images/icons/file-jpg-icon.svg';
import {ReactComponent as FileImgIcon} from '../../../assets/images/icons/file-img-icon.svg';

export const Icon = {
  // SideNavbar
  'bar-chart-icon-gray': BarChartIconGray,
  'bar-chart-icon-white': BarChartIconWhite,
  'check-done-icon-gray': CheckDoneIconGray,
  'check-done-icon-bold': CheckDoneIconBold,
  'home-icon-white': HomeIconWhite,
  'home-icon-gray': HomeIconGray,
  'layers-icon-gray': LayersIconGray,
  'layers-icon-white': LayersIconWhite,
  'pie-chart-icon-gray': PieChartIconGray,
  'pie-chart-icon-white': PieChartIconWhite,
  'gear-icon-gray': GearIconGray,
  'coin-stacked-icon-gray': CoinStackedIconGray,
  'coin-stacked-icon-white': CoinStackedIconWhite,
  'wallet-icon-gray': WalletIconGray,
  'wallet-icon-white': WalletIconWhite,
  'bank-note-icon-gray': BankNoteIconGray,
  'bank-note-icon-bold': BankNoteIconBold,
  'coin-swap-icon-gray': CoinSwapIconGray,
  'coin-swap-icon-white': CoinSwapIconWhite,
  'activity-icon-gray': ActivityIconGray,
  'activity-icon-bold': ActivityIconBold,
  'award-icon-gray': AwardIconGray,
  'award-icon-bold': AwardIconBold,
  'annotation-question-icon-gray': AnnotationQuestionIconGray,
  'annotation-question-icon-bold': AnnotationQuestionIconBold,
  'briefcase-icon-gray': BriefcaseIconGray,
  'briefcase-icon-bold': BriefcaseIconBold,
  'search-refraction-icon-gray': SearchRefractionIconGray,
  'search-refraction-icon-white': SearchRefractionIconWhite,
  'settings-icon-gray': SettingsIconGray,
  'settings-icon-white': SettingsIconWhite,
  'users-icon-gray': UsersIconGray,
  'users-icon-white': UsersIconWhite,
  'warning-icon': WarningIcon,
  // Categories
  'anniversary-bonus': AnniversaryBonusIcon,
  'business-travel': BusinessTravelIcon,
  caregiving: CaregivingIcon,
  cellphone: CellphoneIcon,
  'social-impact': CharitableGivingIcon,
  'commuter-parking': CommuterParkingIcon,
  'commuter-transit': CommuterTransitIcon,
  'compt-internal': PersonalDevelopmentIcon,
  coworking: CoworkingIcon,
  entertainment: EntertainmentIcon,
  experiences: ExperiencesIcon,
  family: FamilyIcon,
  'financial-wellness': FinancialWellnessIcon,
  food: FoodIcon,
  home: HomeIcon,
  internet: InternetIcon,
  'personal-development': PersonalDevelopmentIcon,
  'personal-travel': PersonalTravelIcon,
  pets: PetsIcon,
  productivity: ProductivityIcon,
  'professional-certifications': ProfessionalCertificationsIcon,
  'professional-development': ProfessionalDevelopmentIcon,
  'career-development': ProfessionalDevelopmentIcon,
  'remote-equipment': RemoteEquipmentIcon,
  'safety-equipment': SafetyEquipmentIcon,
  'student-loan-repayment': StudentLoansIcon,
  'team-events': TeamEventsIcon,
  tech: TechIcon,
  'treat-yourself': TreatYourselfIcon,
  wellness: WellnessIcon,
  // Other
  'alert-circle-icon': AlertCircleIcon,
  'alert-circle-blue-icon': AlertCircleBlueIcon,
  'alert-circle-gray-icon': AlertCircleGrayIcon,
  'alert-circle-error-icon': AlertCircleWarningIcon,
  'alert-circle-warning-icon': AlertCircleWarningIcon,
  'alert-triangle-warning-icon': AlertTriangleWarningIcon,
  'alert-circle-black-icon': AlertCircleBlackIcon,
  'alert-circle-question-blue-icon': AlertCircleQuestionBlueIcon,
  'arrow-narrow-right': NarrowArrowRightIcon,
  calendar: CalendarIcon,
  'chevron-left-icon': ChevronLeftIcon,
  'chevron-left-double-icon': ChevronLeftDoubleIcon,
  'chevron-right-icon': ChevronRightIcon,
  'chevron-right-icon-gray': ChevronRightIconGray,
  'chevron-right-icon-gray-700': ChevronRightIconGray700,
  'chevron-right-double-icon': ChevronRightDoubleIcon,
  'chevron-down-icon': ChevronDownIcon,
  'ellipse-vertical-icon': EllipseVerticalIcon,
  'filter-lines-blue-icon': FilterLinesBlueIcon,
  'google-icon': GoogleIcon,
  'slack-icon': SlackIcon,
  'plus-icon': PlusIcon,
  'plus-icon-gray': PlusIconGray,
  'plus-icon-blue': PlusIconBlue,
  'document-icon': DocumentIcon,
  'trash-icon': TrashIcon,
  'trash-icon-white': TrashIconWhite,
  'red-trash-icon': RedTrashIcon,
  'upload-receipt-icon': UploadReceiptIcon,
  'eye-icon': EyeIcon,
  'x-icon': XIcon,
  'x-icon-styleable': XIconStyleable,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'pencil-icon': PencilIcon,
  'plus-circle': PlusCircle,
  'minus-circle': MinusCircle,
  'magnifying-glass': MagnifyingGlass,
  'help-circle': HelpCircle,
  'save-icon': SaveIcon,
  'check-icon': CheckIcon,
  'circle-check-icon': CircleCheckIcon,
  'circle-check-green-icon': CircleCheckGreenIcon,
  'circle-check-green-border-icon': CircleCheckGreenBorderIcon,
  'document-failed-icon': DocumentFailedIcon,
  'log-out-icon': LogOutIcon,
  'menu-icon': MenuIcon,
  'error-404': Error404,
  'red-circle-trash-icon': RedCircleTrashIcon,
  'yellow-folder-icon': YellowFolderIcon,
  'file-question-icon': FileQuestionIcon,
  'expiration-indicator-0': ExpirationIndicator0,
  'expiration-indicator-50': ExpirationIndicator50,
  'expiration-indicator-75': ExpirationIndicator75,
  'expiration-indicator-95': ExpirationIndicator95,
  'featured-icon': FeaturedIcon,
  'arrow-circle-collapse-icon': ArrowCircleBrokenLeft,
  'arrow-circle-expand-icon': ArrowCircleBrokenRight,
  'star-icon': StarIcon,
  'blue-star-icon': BlueStarIcon,
  'megaphone-icon': MegaphoneIcon,
  'megaphone-icon-white': MegaphoneIconWhite,
  'check-verified-icon': CheckVerifiedIcon,
  'receipt-icon': ReceiptIcon,
  // Employee Account Settings
  'file-lock-icon': FileLockIcon,
  'file-icon': FileIcon,
  'file-icon-blue': FileIconBlue,
  'user-icon': UserIcon,
  'password-lock-icon': PasswordLockIcon,
  'settings-slider-icon': SettingsSliderIcon,
  'upload-icon': UploadIcon,
  'file-pdf-icon': FilePdfIcon,
  'file-png-icon': FilePngIcon,
  'file-eps-icon': FileEpsIcon,
  'file-svg-icon': FileSvgIcon,
  'file-gif-icon': FileGifIcon,
  'file-tiff-icon': FileTiffIcon,
  'file-webp-icon': FileWebpIcon,
  'file-jpeg-icon': FileJpegIcon,
  'file-jpg-icon': FileJpgIcon,
  'file-img-icon': FileImgIcon,
  'empty-img-icon': EmptyImageIcon,
};

interface IProps {
  iconName: string;
  className?: string;
  svgProp?: React.SVGProps<SVGSVGElement>;
  isCategoryIcon?: boolean;
  labelHidden?: boolean;
  ariaLabel?: string;
}

export const ComptSvgIcon = (props: IProps) => {
  const {iconName, className, svgProp, ariaLabel, labelHidden = true} = props;
  const iconNameKey = iconName as keyof typeof Icon;
  const IconComponent = Icon[iconNameKey] || PieChartIconWhite;
  const ariaProps = labelHidden ? null : {role: 'img', 'aria-label': ariaLabel};

  return (
    <>
      {IconComponent && (
        <div className={className}>
          <IconComponent {...ariaProps} {...svgProp} />
        </div>
      )}
    </>
  );
};
