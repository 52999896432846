import {ComptRTKTags, comptApiSlice} from '@compt/app/services/api/api-slice';
import {
  BusinessExpense,
  BusinessExpenseListResults,
} from '@compt/types/business-expenses/business-expense';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessExpenseList: builder.query<
      BusinessExpenseListResults,
      {companyId?: number; reportId?: string | number}
    >({
      providesTags: [ComptRTKTags.BUSINESS_EXPENSE_LIST],
      query: ({companyId, reportId}) =>
        `/business_expenses/${companyId}/me/${reportId}/business_expenses`,
    }),
    createBusinessExpense: builder.mutation<BusinessExpense, Partial<BusinessExpense>>({
      invalidatesTags: (result, error, businessExpense) => {
        const reportId = businessExpense.report_id;
        return [
          {type: ComptRTKTags.BUSINESS_EXPENSE_REPORT, reportId},
          ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
          ComptRTKTags.BUSINESS_EXPENSE_LIST,
        ];
      },
      query(body) {
        return {
          url: 'business_expenses/business_expenses/new',
          method: 'POST',
          body,
        };
      },
    }),
    updateBusinessExpense: builder.mutation<Partial<BusinessExpense>, BusinessExpense>({
      invalidatesTags: (result, error, businessExpense) => [
        ComptRTKTags.BUSINESS_EXPENSE_REPORT,
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
        ComptRTKTags.BUSINESS_EXPENSE_LIST,
      ],
      query(data) {
        const {id, ...body} = data;

        return {
          url: `business_expenses/business_expenses/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteBusinessExpense: builder.mutation<{success: boolean; id: number}, number>({
      invalidatesTags: [
        ComptRTKTags.BUSINESS_EXPENSE_REPORT,
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
        ComptRTKTags.BUSINESS_EXPENSE_LIST,
      ],
      query(id) {
        return {
          url: `business_expenses/business_expenses/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetBusinessExpenseListQuery,
  useCreateBusinessExpenseMutation,
  useUpdateBusinessExpenseMutation,
  useDeleteBusinessExpenseMutation,
} = extendedComptApiSlice;
