import {ComptStyleType} from '@compt/utils/style-helpers';

export enum BusinessExpenseStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROCESSED = 'PROCESSED',
  DRAFT = 'DRAFT',
  REVIEWED_BY_MANAGER = 'REVIEWED_BY_MANAGER',
  REVIEWED = 'REVIEWED',
}

interface StatusFormatItems {
  statusName: string;
  statusStyle: ComptStyleType;
}

export const reportStatusStrings: {[key: string]: BusinessExpenseStatus} = {
  DRAFT: BusinessExpenseStatus.DRAFT,
  SUBMITTED: BusinessExpenseStatus.SUBMITTED,
  REJECTED: BusinessExpenseStatus.REJECTED,
  REVIEWED: BusinessExpenseStatus.REVIEWED,
  REVIEWED_BY_MANAGER: BusinessExpenseStatus.REVIEWED_BY_MANAGER,
  PROCESSED: BusinessExpenseStatus.PROCESSED,
};

export const expenseReportStatusFormats: Record<BusinessExpenseStatus, StatusFormatItems> = {
  OPEN: {statusName: 'Open', statusStyle: ComptStyleType.PRIMARY},
  SUBMITTED: {statusName: 'Submitted', statusStyle: ComptStyleType.SECONDARY},
  APPROVED_BY_MANAGER: {statusName: 'Approved by manager', statusStyle: ComptStyleType.SUCCESS},
  APPROVED: {statusName: 'Approved', statusStyle: ComptStyleType.SUCCESS},
  REJECTED: {statusName: 'Rejected', statusStyle: ComptStyleType.DESTRUCTIVE},
  PROCESSED: {statusName: 'Processed', statusStyle: ComptStyleType.PRIMARY},
  DRAFT: {statusName: 'Draft', statusStyle: ComptStyleType.GRAY},
  REVIEWED_BY_MANAGER: {statusName: 'Reviewed by manager', statusStyle: ComptStyleType.SUCCESS},
  REVIEWED: {statusName: 'Reviewed', statusStyle: ComptStyleType.SUCCESS},
};
