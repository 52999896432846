import React from 'react';

export type ComptNoPermissionsPageProps = {
  heading: string;
  description: string;
};
export const NoPermissionsPage = (props: ComptNoPermissionsPageProps) => (
  <div className="flex justify-center mt-32 screen" data-testid="no-permissions-message">
    <div className="absolute mt-20">
      <h1 className="display1 compt-text--semibold text-center">{props.heading}</h1>
      <p className="body3 text-color-body2">{props.description}</p>
    </div>
  </div>
);
