import React, {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {CostCenter} from '@compt/types/cost-center';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';
import {ComptNumberField} from '@compt/common/forms/compt-number-field/compt-number-field';
import {ComputedRateText} from './computed-rate-text';
import {BusinessExpenseType} from '@compt/types/business-expenses/business-expense';
import {RouteAndDateRangeFields} from './route-and-date-range-fields';
import {SharedBusinessExpenseFields} from './shared-business-expense-fields';
import {Company} from '@compt/types/company';

type MileageExpenseFormProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  assignedCostCenter: CostCenter | undefined;
  company: Company;
  isEditable?: boolean;
  isCreateForm?: boolean;
};

export const MileageExpenseForm = (props: MileageExpenseFormProps) => {
  const {isCreateForm, assignedCostCenter, company, formMethods} = props;
  const isDisabled = !props.isEditable;

  const canSelectCostCenter =
    company.business_expense_configuration?.users_may_select_cost_center || false;

  const defaultMileageCategory =
    company.business_expense_configuration?.default_mileage_expense_category;

  const mileageRate = company.mileage_rate;

  useEffect(() => {
    if (isCreateForm) {
      controller.resetFields(formMethods, assignedCostCenter);
      formMethods.resetField('expense_category', {
        defaultValue: defaultMileageCategory,
      });

      controller.validateCostCenterOnCreate(canSelectCostCenter, assignedCostCenter, formMethods);
      controller.validateExpenseCategoryOnCreate(defaultMileageCategory, formMethods);
    }
  }, [isCreateForm, formMethods, assignedCostCenter, defaultMileageCategory, canSelectCostCenter]);

  return (
    <div>
      <RouteAndDateRangeFields
        formMethods={formMethods}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <ComptNumberField
        name="distance"
        label="Distance (miles)"
        data-testid="distance-field"
        placeholder="ex. 265.5"
        stepValue=".1"
        decimalPlaces={1}
        control={formMethods.control}
        register={formMethods.register}
        validation={{required: 'Distance is required', validate: (value) => value != 0}}
        errors={formMethods.formState.errors.distance}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      <SharedBusinessExpenseFields
        formMethods={formMethods}
        company={company}
        expenseCategoryDisabled={!!defaultMileageCategory}
        disabled={isDisabled}
        readOnly={!props.isEditable}
      />
      {mileageRate && (
        <ComputedRateText
          expenseType={BusinessExpenseType.MILEAGE}
          form={formMethods.watch()}
          expenseTypeRate={mileageRate}
        />
      )}
    </div>
  );
};
