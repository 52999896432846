import React from 'react';
import {BusinessExpenseType} from '@compt/types/business-expenses/business-expense';
import {FieldValues} from 'react-hook-form';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';

type ComputedRateTextProps = {
  expenseType: BusinessExpenseType.MILEAGE | BusinessExpenseType.PER_DIEM;
  form: FieldValues;
  expenseTypeRate: string;
};

export const ComputedRateText = (props: ComputedRateTextProps) => {
  const {expenseType, form, expenseTypeRate} = props;

  const expenseTypeRateAsNum = parseFloat(expenseTypeRate);
  let totalRate = '';
  let rateText = '';
  let rateSubtext = '';

  if (expenseType === BusinessExpenseType.MILEAGE) {
    const formattedDistance = form.distance ? parseFloat(form.distance).toFixed(1) : 0;
    totalRate = controller
      .calculateTotalFromRateMileage(formattedDistance, expenseTypeRateAsNum)
      .toFixed(2)
      .toString();

    rateText = 'Calculation by distance';
    rateSubtext = `${expenseTypeRate} per mile`;
  }

  if (expenseType === BusinessExpenseType.PER_DIEM) {
    const totalDays = controller.calculateTotalDays(form.start_date, form.end_date);
    const isRateNaN = form.per_diem_rate === 'NaN';

    totalRate = !isRateNaN
      ? controller
          .calculateTotalFromRatePerDiem(totalDays, form.per_diem_rate ?? 0)
          .toFixed(2)
          .toString()
      : '0.00';

    rateText = 'Calculation by dates';
    rateSubtext = `${isRateNaN ? '0.00' : form.per_diem_rate} per night`;
  }

  return (
    <div className="grid gap-y-2 py-6">
      <div className="flex justify-between">
        <p className="body1">Expense amount</p>
        <p className="label1 text-color-heading">${totalRate ?? '0.00'}</p>
      </div>
      <div className="flex justify-between">
        <p className="body1">{rateText}</p>
        <p className="label1 text-color-heading">{rateSubtext}</p>
      </div>
    </div>
  );
};
