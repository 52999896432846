import React, {HTMLAttributes, useCallback, useContext, useMemo} from 'react';
import {ComptSideNavButton} from './compt-side-nav-button';
import {SideNavItemType} from './compt-side-nav.types';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {SideNavContext} from './side-nav-context';
import {SECTION_TYPE} from '../compt-layout/compt-layout.controller';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {Transition} from '@headlessui/react';

interface CollapsibleSideNavSectionProps extends HTMLAttributes<HTMLElement> {
  sectionTitle?: string;
  sectionItems: SideNavItemType[];
  sectionIcon?: string;
  sectionType: SECTION_TYPE;
  id: string;
}

export const ComptCollapsibleSideNavSection = (props: CollapsibleSideNavSectionProps) => {
  const {
    sidebarOpen,
    expenseAdminOpen,
    setExpenseAdminOpen,
    stipendAdminOpen,
    setStipendAdminOpen,
    internalAdminOpen,
    setInternalAdminOpen,
  } = useContext(SideNavContext);
  const isMobileView = useIsMobileView();

  const isStipendAdmin = props.sectionType === SECTION_TYPE.STIPEND_ADMIN;
  const isExpenseAdmin = props.sectionType === SECTION_TYPE.EXPENSE_ADMIN;
  const isInternalAdmin = props.sectionType === SECTION_TYPE.INTERNAL_ADMIN;

  /** Routes to the correct open state, based on admin section. */
  const mobileAccordionOpen = useMemo(() => {
    if (isStipendAdmin) {
      return stipendAdminOpen;
    }

    if (isExpenseAdmin) {
      return expenseAdminOpen;
    }

    if (isInternalAdmin) {
      return internalAdminOpen;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipendAdminOpen, expenseAdminOpen, internalAdminOpen]);

  /** Routes to the correct set open function, based on admin section. */
  const setMobileAccordionOpen = useCallback(() => {
    if (isStipendAdmin) {
      return setStipendAdminOpen((prevState) => !prevState);
    }

    if (isExpenseAdmin) {
      return setExpenseAdminOpen((prevState) => !prevState);
    }

    if (isInternalAdmin) {
      return setInternalAdminOpen((prevState) => !prevState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipendAdminOpen, expenseAdminOpen, internalAdminOpen]);

  const setAdminSectionOpen = useCallback(() => {
    if (isStipendAdmin) {
      // Make sure the other secondary navs are closed
      if (expenseAdminOpen) {
        setExpenseAdminOpen((prevState) => !prevState);
      } else if (internalAdminOpen) {
        setInternalAdminOpen((prevState) => !prevState);
      }

      if (!stipendAdminOpen) {
        return setStipendAdminOpen((prevState) => !prevState);
      }
    }

    if (isExpenseAdmin) {
      if (stipendAdminOpen) {
        setStipendAdminOpen((prevState) => !prevState);
      } else if (internalAdminOpen) {
        setInternalAdminOpen((prevState) => !prevState);
      }

      if (!expenseAdminOpen) {
        return setExpenseAdminOpen((prevState) => !prevState);
      }
    }

    if (isInternalAdmin) {
      if (stipendAdminOpen) {
        setStipendAdminOpen((prevState) => !prevState);
      } else if (expenseAdminOpen) {
        setExpenseAdminOpen((prevState) => !prevState);
      }

      if (!internalAdminOpen) {
        return setInternalAdminOpen((prevState) => !prevState);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipendAdminOpen, expenseAdminOpen, internalAdminOpen]);

  return (
    <>
      <button
        type="button"
        className="flex w-full justify-between items-center
          compt-button side-nav-inactive"
        onMouseEnter={() => !isMobileView && setAdminSectionOpen()}
        onClick={() => (isMobileView ? setMobileAccordionOpen() : null)}
        // In-line style necessary to override admin page stylesheet
        style={{borderRadius: '6px', padding: '8px 12px'}}
      >
        <div className="flex">
          <ComptSvgIcon iconName={props.sectionIcon ?? ''} />
          <Transition
            show={sidebarOpen}
            enter="transition-opacity duration-300 delay-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <p className={'body2 text-color-body1 flex justify-start whitespace-nowrap pl-3'}>
              {props.sectionTitle}
            </p>
          </Transition>
        </div>
        <Transition
          show={sidebarOpen}
          enter="transition-opacity duration-300 delay-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComptSvgIcon
            iconName="chevron-right-icon"
            svgProp={{transform: isMobileView && mobileAccordionOpen ? 'rotate(90)' : ''}}
          />
        </Transition>
      </button>
      <div className={`${isMobileView && mobileAccordionOpen ? '' : 'mt-3 ml-2 hidden'}`}>
        {props.sectionItems.map((navItem, index) => (
          <ComptSideNavButton
            key={index}
            iconId={navItem.iconId}
            pagePath={navItem.pagePath}
            isHref={navItem.isHref}
            excludeIcon={true}
          >
            {navItem.title}
          </ComptSideNavButton>
        ))}
      </div>
    </>
  );
};
