import React, {Dispatch, SetStateAction} from 'react';
import {Draft, produce} from 'immer';
import _ from 'lodash';
import {TableColumn} from 'react-data-table-component';
import {
  SelectionChangeValues,
  FilterConfigOption,
  ChangeValueTypes,
  FilterValues,
  FilterValueTypes,
  FilterConfigItem,
} from '../compt-filter-bar/compt-filter-bar.types';
import {ComptTableProps, ComptTableColumn} from './compt-table.types';

const sortingOperators = {
  desc: '-',
  asc: '',
};

export class ComptTableController<T> {
  constructor(props: ComptTableProps<T>, public setProps: SetStateAction<any>) {}

  getTotalPages = (totalCount: number, itemsPerPage: number) =>
    Math.ceil(totalCount / itemsPerPage);

  getColumnsList = (props: ComptTableProps<T>) => {
    const columnsList = Object.keys(props.columnDefinition)
      .map((key) => props.columnDefinition[key])
      .sort((a, b) => a.order - b.order);
    const visibleColumns = _.pickBy(props.columnDefinition, (column) => !column.omit);
    const shownColumnIds = this.getShownColumnIds(props, visibleColumns);

    this.setProps((prevProps: ComptTableProps<T>) => ({
      ...prevProps,
      columnsList: this.getFormattedColumns(columnsList, visibleColumns),
      visibleColumns,
      shownColumnIds,
    }));
  };

  getVisibleColumns = (props: ComptTableProps<T>) =>
    props.columnsList
      ?.filter((column: ComptTableColumn<T>) => !column.omit)
      .reduce((previousValue: {[key: string]: boolean}, column: ComptTableColumn<T>) => {
        previousValue[column.id || ''] = true;
        return previousValue;
      }, {});

  _filterChanged = (
    {
      filterKey,
      changeValue,
    }: {
      filterKey: keyof FilterValues;
      changeValue: ChangeValueTypes;
    },
    props: ComptTableProps<T>,
    setCurrentFilterValues: Dispatch<SetStateAction<Record<string, FilterValueTypes> | undefined>>,
  ) => {
    const valuesChangeHandler = props.filterConfiguration?.[filterKey]?.valuesChangeHandler;
    const changedFilterValues = ComptTableController.generateValueChangeDraft(
      {filterKey, changeValue},
      valuesChangeHandler,
    );

    setCurrentFilterValues(changedFilterValues);
  };

  /**
   * Generates a draft of the filter values being changed.
   * Depending on the type of filter, will alter each draft[filterKey] to be an array of strings,
   * Selection Options, or Range Value Option
   */
  static generateValueChangeDraft(
    {
      filterKey,
      changeValue,
    }: {
      filterKey: keyof FilterValues;
      changeValue: ChangeValueTypes;
    },
    valuesChangeHandler?: FilterConfigItem['valuesChangeHandler'],
  ): Draft<FilterValues> {
    const changedValues = produce((draft: Draft<FilterValues>) => {
      if (!draft?.[filterKey]) {
        console.error(
          `Draft is either null or key '${filterKey}' does not exist.
          Define ${filterKey} in your filter configurations and initial filter values of the ComptTable`,
        );
        return;
      }

      if (filterKey === 'textSearch' && typeof changeValue === 'string') {
        draft[filterKey] = [changeValue];
        return draft;
      }

      if (!valuesChangeHandler) {
        console.error(
          'A value change handler does not exist for a data structure that requires it.',
        );
        return;
      }

      const valueChangeDraft = valuesChangeHandler(draft[filterKey], changeValue);

      if (!valueChangeDraft) {
        console.error('Value change handler did not return a valid draft.');
        return;
      }

      draft[filterKey] = valueChangeDraft as any;

      return draft;
    });

    return changedValues as Draft<FilterValues>;
  }

  handleSort = async (
    column: TableColumn<T>,
    sortDirection: 'desc' | 'asc',
    props: ComptTableProps<T>,
  ) => {
    let ordering: string | undefined;
    const isAsc = sortDirection === 'asc';

    if (props.columnDefinition[column.id || '']?.prepareSortField) {
      ordering = props.columnDefinition[column.id || '']?.prepareSortField?.(isAsc);
    } else {
      ordering = `${sortingOperators[sortDirection]}${column.sortField || column.id}`;
    }

    this.setProps((prevProps: ComptTableProps<T>) => ({
      ...prevProps,
      ordering,
      orderingOptions: {fieldId: column.id, isAsc: isAsc},
    }));
  };

  getOrderedColumns: (props: ComptTableProps<T>) => FilterConfigOption[] | undefined = (
    props: ComptTableProps<T>,
  ) => {
    const columnsToOrder = props.columnsList?.filter(
      (column: ComptTableColumn<T>) => !column.disableRemoval,
    );

    const orderedFilters: FilterConfigOption[] | undefined = columnsToOrder?.map(
      (column: ComptTableColumn<T>): FilterConfigOption => {
        const filterOption: {[key: string]: string | number} = {};
        if (column.id) {
          filterOption.id = column.id;
        }
        if (column.name) {
          filterOption.name = column.name.toString();
        }
        return filterOption as FilterConfigOption;
      },
    );

    return orderedFilters;
  };

  getFormattedColumns = (
    columnsList?: ComptTableColumn<T>[],
    visibleColumns?: {[key: string]: boolean | ComptTableColumn<T>},
  ) =>
    columnsList?.map((column: ComptTableColumn<T>) => ({
      ...column,
      omit: column.id && !visibleColumns?.[column.id],
      selector: (row: any) => {
        const value = column.selector?.(row);
        const formattedValue = typeof value === 'bigint' ? value.toString() : value;

        if (formattedValue || formattedValue === 0) {
          if (column.enableToolTip) {
            return (
              <div style={{height: '72px', display: 'flex', alignItems: 'center'}}>
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={column.getAllText ? column.getAllText(row) : value?.toString()}
                >
                  {formattedValue}
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                height: '72px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {formattedValue}
            </div>
          );
        }

        return '-';
      },
    }));

  getCleanedDataTableColumns = (props: ComptTableProps<T>): TableColumn<T>[] =>
    props.columnsList
      ? props.columnsList.map((column) => {
          const {enableToolTip, getAllText, ...rest} = column;
          return rest as TableColumn<T>;
        })
      : [];

  getShownColumnIds = (props: ComptTableProps<T>, visibleColumns?: {[key: string]: any}) =>
    visibleColumns &&
    Object.keys(visibleColumns).map((key) => ({
      id: key,
      name: props.columnDefinition[key].name,
    }));

  handleColumnsChange = (change: SelectionChangeValues, props: ComptTableProps<T>) => {
    const visibleColumns = produce(props.visibleColumns, (draft: any) => {
      if (change.checked && change.selection.id) {
        draft[change.selection.id] = change.checked;
      } else {
        change.selection.id && delete draft[change.selection.id];
      }
    });

    this.setProps((prevProps: ComptTableProps<T>) => ({
      ...prevProps,
      visibleColumns,
      columnsList: this.getFormattedColumns(props.columnsList, visibleColumns),
      shownColumnIds: this.getShownColumnIds(props, visibleColumns),
    }));
  };

  handlePageChange = async (
    page: number,
    props: ComptTableProps<T>,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    currentFilterValues?: FilterValues,
  ) => {
    setCurrentPage(page);
    props.itemsPerPage &&
      currentFilterValues &&
      props.onChangeQueryValues?.(currentFilterValues, {page, limit: props.itemsPerPage});
  };
}
