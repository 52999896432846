import React, {useState} from 'react';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetBusinessExpenseListQuery} from '@compt/app/services/api/business-expenses-slice';
import {
  useGetBusinessExpenseReportQuery,
  useUpdateBusinessExpenseReportMutation,
} from '@compt/app/services/api/business-expense-reports-slice';
import {Link, useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {BusinessExpenseTable} from './components/business-expense-table/business-expense-table.container';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {BusinessReportSidePanel} from '../components/business-report-side-panel';
import {
  BusinessExpenseStatus,
  expenseReportStatusFormats,
} from '@compt/types/business-expenses/business-expense-statuses';
import {DeleteExpenseReportModal} from './components/delete-business-report-modal';
import {SubmitExpenseReportModal} from './components/submit-business-report-modal';
import {ReportFooterText} from './components/report-page-footer-text';
import {FieldValues} from 'react-hook-form';
import {BusinessExpensePolicyModal} from '@compt/pages/business-expenses/components/business-expense-policy-modal';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {EmployeeBusinessExpenseFormSidePanel} from './components/employee-business-expense-form/employee-business-expense-form-side-panel';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {BusinessExpensePageContextProvider} from './business-expense-page.context';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ReportPageActionMenu} from '@compt/pages/business-expenses/business-expense-page/components/report-page-action-menu';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';

export const BusinessExpensePage = () => {
  const isMobileView = useIsMobileView();

  const {reportId} = useParams();

  const [openReportActionMenu, setOpenReportActionMenu] = useState(false);
  const [openEditSidePanel, setOpenEditSidePanel] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [openCreateExpenseSidePanel, setOpenCreateExpenseSidePanel] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const userCountryCode = session?.data?.country as SupportedCountriesType;
  const hasRequiredRole = session.data?.roles.includes(USER_ROLES.businessExpenseEligible);

  const reportInfoQuery = useGetBusinessExpenseReportQuery(reportId ?? skipToken);
  const reportInfo = reportInfoQuery.data;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const company = companyQuery.data;

  const expenseListQuery = useGetBusinessExpenseListQuery({companyId: company?.id, reportId});
  const expenseListResults = expenseListQuery.data?.results;

  const [updateBusinessExpenseReport, {isLoading}] = useUpdateBusinessExpenseReportMutation();

  if (reportInfoQuery.isLoading || companyQuery.isLoading || expenseListQuery.isLoading) {
    return <ComptLoadingIndicator isLoading={true} className="h-full" />;
  }

  if (
    !hasRequiredRole ||
    (reportInfoQuery.error &&
      'status' in reportInfoQuery.error &&
      reportInfoQuery.error.status === 404)
  ) {
    return <Error404Page />;
  }

  if (!reportInfoQuery.isSuccess || !companyQuery.isSuccess || !expenseListQuery.isSuccess) {
    // TODO: What's the appropriate error to show? COMPT-4520
    return <div>Error!</div>;
  }

  if (!reportInfo || !company || !expenseListResults) {
    return null;
  }

  const isDraft = reportInfo.status === BusinessExpenseStatus.DRAFT;
  const {statusStyle, statusName} = expenseReportStatusFormats[reportInfo.status];

  const getStatusPill = () => (
    <ComptPill className="mb-1" pillSize={ComptPillSize.LARGE} pillType={statusStyle}>
      {statusName}
    </ComptPill>
  );
  const expenseListExists = expenseListResults.length > 0;

  const onSubmitEditReport = (form: FieldValues) => {
    const submission = {
      ...reportInfo,
      ...form,
    };

    updateBusinessExpenseReport(submission).then(() => {
      setOpenEditSidePanel(false);
      triggerCustomToast('success', 'Successfully updated report');
    });
  };

  return (
    <BusinessExpensePageContextProvider>
      <>
        <BusinessReportSidePanel
          reportInfo={reportInfo}
          open={openEditSidePanel}
          setOpen={setOpenEditSidePanel}
          onSubmit={onSubmitEditReport}
          mutationLoading={isLoading}
        />
        <DeleteExpenseReportModal
          reportInfo={reportInfo}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
        />
        <SubmitExpenseReportModal
          reportInfo={reportInfo}
          open={openSubmitModal}
          setOpen={setOpenSubmitModal}
        />
        <EmployeeBusinessExpenseFormSidePanel
          open={openCreateExpenseSidePanel}
          setOpen={setOpenCreateExpenseSidePanel}
          reportInfo={reportInfo}
        />
        <BusinessExpensePolicyModal
          open={openPolicyModal}
          setOpen={setOpenPolicyModal}
          company={company}
        />
        <ComptPage
          title={reportInfo.title}
          statusPill={getStatusPill()}
          subtitle={reportInfo.description}
          includeBottomHR={false}
          header={
            <div className="flex flex-row gap-3 mb-4 items-center">
              <Link to="/business-expenses">
                <p className="label4 text-color-link">Expense reports</p>
              </Link>
              <ComptSvgIcon iconName="chevron-right-icon-gray" />
              <p className="label4 text-color-body1">{reportInfo?.title}</p>
            </div>
          }
          action={
            <>
              {isDraft && (
                <div className="flex flex-col justify-end sm:flex-row sm:space-x-3 gap-y-1 mt-5">
                  <ReportPageActionMenu
                    isMobile={isMobileView}
                    openReportActionMenu={openReportActionMenu}
                    setOpenReportActionMenu={setOpenReportActionMenu}
                    setOpenEditSidePanel={setOpenEditSidePanel}
                    setOpenDeleteModal={setOpenDeleteModal}
                  />
                  <ComptButton
                    buttonType={ComptButtonType.SECONDARY}
                    size={ComptButtonSize.LARGE}
                    className={`block ${isMobileView && 'w-full'}`}
                    disabled={!expenseListExists}
                    onClick={() => setOpenSubmitModal(true)}
                  >
                    Submit for approval
                  </ComptButton>
                  <ComptButton
                    buttonType={ComptButtonType.PRIMARY}
                    iconId={ComptButtonIcon.PLUS}
                    size={ComptButtonSize.LARGE}
                    className={`mr-3 ${isMobileView && 'w-full'}`}
                    textClassName="text-color-light"
                    onClick={() => setOpenCreateExpenseSidePanel(true)}
                    data-testid="add-expense-button"
                  >
                    Add expense
                  </ComptButton>
                </div>
              )}
            </>
          }
        >
          <ComptRow>
            <div className="flex justify-end">
              {company.business_expense_configuration?.business_expense_policy ? (
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  onClick={() => setOpenPolicyModal(true)}
                  textClassName="text-link"
                >
                  Expense Policy
                </ComptButton>
              ) : company.business_expense_configuration?.link ? (
                <ComptLink
                  link={company.business_expense_configuration.link}
                  newTab={true}
                  className="mb-2.5"
                >
                  Expense Policy
                </ComptLink>
              ) : (
                ''
              )}
            </div>
          </ComptRow>
          <ComptRow>
            <BusinessExpenseTable expenseListResults={expenseListResults} company={company} />
          </ComptRow>
          <ComptRow>
            <ReportFooterText
              reportInfo={reportInfo}
              userCountryCode={userCountryCode}
              expenseListExists={expenseListExists}
            />
          </ComptRow>
        </ComptPage>
      </>
    </BusinessExpensePageContextProvider>
  );
};
