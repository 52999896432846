export enum USER_ROLES {
  internalAdmin = 'is_internal_admin',
  admin = 'is_admin',
  stipendProgramManager = 'is_stipend_program_manager',
  delegate = 'is_delegate',
  businessExpenseProgramManager = 'is_business_expense_program_manager',
  payrollProcessor = 'is_payroll_processor',
  stipendReviewer = 'is_stipend_reviewer',
  employeeManager = 'is_employee_manager',
  financeReviewer = 'is_finance_reviewer',
  stipendEligible = 'is_stipend_eligible',
  businessExpenseEligible = 'is_business_expense_eligible',
  auditor = 'is_auditor',
}
