import React from 'react';
import {
  Controller,
  FieldValues,
  UseFormSetValue,
  FieldPathByValue,
  PathValue,
} from 'react-hook-form';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {ComptDropDown, ComptDropDownProps} from '../compt-dropdown/compt-dropdown';
import {ComptFormControlFieldSingleSelectBaseProps} from '@compt/types/form/compt-forms';

export interface ComptDropDownFieldProps<
  TOptionType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TOptionType>,
> extends Omit<ComptDropDownProps<TOptionType>, 'value' | 'onChange' | 'name' | 'initialValue'>,
    Omit<ComptFormControlFieldSingleSelectBaseProps<TOptionType, TFieldValues, TName>, 'value'> {
  getSecondaryText?: (item: TOptionType) => string;
  /**
   * @deprecated use onChange
   * @param selected
   * @param setValue
   */
  onSelect?: (selected: any, setValue?: any) => void;
  /**
   * @deprecated use onChange
   * @param selected
   * @param setValue
   */
  setValue?: UseFormSetValue<FieldValues>;
  value?: PathValue<TFieldValues, TName>;
  onChange?: () => void;
  invalidOptions?: TOptionType[];
  readOnly?: boolean;
}

export const ComptDropDownField = <
  TOptionType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TOptionType>,
>(
  props: ComptDropDownFieldProps<TOptionType, TFieldValues, TName>,
) => {
  const field = (
    <Controller<TFieldValues, TName>
      name={props.name}
      control={props.control}
      defaultValue={props.value || undefined}
      rules={{
        validate: props.validation?.validate || {
          required: (value) => !(props.validation?.required && !value),
        },
      }}
      render={({field}) => (
        <ComptDropDown
          id={props.id}
          name={props.name}
          value={field.value}
          options={props.options}
          invalidOptions={props.invalidOptions}
          onChange={(option: TOptionType) => {
            field.onChange(option);
            props.onChange && props.onChange();
          }}
          getKey={props.getKey}
          getDisplayText={props.getDisplayText}
          getSecondaryText={props.getSecondaryText}
          placeholder={props.placeholder}
          invalid={!!props.errors}
          onSelect={props.onSelect}
          setValue={props.setValue}
          disabled={props.disabled}
          data-testid={props['data-testid']}
          inputRef={
            (props.register && props.name ? props.register(props.name)?.ref : field.ref) ||
            field.ref
          }
          by={props.by}
        />
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};
