import React from 'react';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {CostCenter} from '@compt/types/cost-center';
import {Company} from '@compt/types/company';
import {BusinessExpenseCategory} from '@compt/types/business-expenses/business-expense-category';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';

type SharedBusinessExpenseFieldProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  company: Company;
  expenseCategoryDisabled: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

export const SharedBusinessExpenseFields = (props: SharedBusinessExpenseFieldProps) => {
  const {formMethods, company, expenseCategoryDisabled} = props;

  const costCenterDisabled = !company.business_expense_configuration?.users_may_select_cost_center;

  const filteredCostCenterOptions = controller.getFilteredExpenseCodeOptions(company.cost_centers);
  const filteredExpenseCategoryOptions = controller.getFilteredExpenseCodeOptions(
    company.business_expense_categories,
  );

  return (
    <div className="space-y-2">
      <div className="grid gap-2">
        <ComptDropDownField
          name="cost_center"
          data-testid="cost-center-field"
          label="Cost center"
          options={filteredCostCenterOptions}
          getKey={(option: CostCenter) => option.id}
          getDisplayText={(option: CostCenter) => option.name}
          validation={{
            required: 'Cost center is required',
            validate: controller.validateCostCenter,
          }}
          disabled={costCenterDisabled || props.disabled}
          register={formMethods.register}
          control={formMethods.control}
          errors={formMethods.formState.errors.cost_center}
          readOnly={props.readOnly}
        />
        <ComptDropDownField
          name="expense_category"
          data-testid="expense-category-field"
          label="Expense category"
          options={filteredExpenseCategoryOptions}
          getKey={(option: BusinessExpenseCategory) => option.id}
          getDisplayText={(option: BusinessExpenseCategory) => option.name}
          validation={{
            required: 'Expense category is required',
            validate: (category: BusinessExpenseCategory) => controller.validateCategory(category),
          }}
          disabled={expenseCategoryDisabled || props.disabled}
          register={formMethods.register}
          control={formMethods.control}
          errors={formMethods.formState.errors.expense_category}
          readOnly={props.readOnly}
        />
      </div>
      <ComptTextAreaField
        name="description"
        label="Description"
        placeholder="Describe the expense"
        validation={{
          required: 'Description is required',
          maxLength: {value: 255, message: 'Description has max 255 characters'},
        }}
        register={formMethods.register}
        errors={formMethods.formState.errors.description}
        readOnly={props.readOnly}
        disabled={props.disabled}
      />
    </div>
  );
};
