import React from 'react';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {
  Controller,
  ControllerRenderProps,
  FieldPathByValue,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetError,
  UseFormUnregister,
} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {ComptReceiptUpload} from '../compt-receipt-upload/compt-receipt-upload.container';

export interface ComptFileUploadFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  ['data-testid']?: string;
  resetField: UseFormResetField<TFieldValues>;
  setError: UseFormSetError<TFieldValues>;
  clearError: UseFormClearErrors<TFieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  domain?: string;
  userId?: number;
  previewReceipt?: boolean;
  comptFormFieldClasses?: string;
  readOnly?: boolean;
  onKeyChange?: (
    receiptKey: string | null,
    getValues: UseFormGetValues<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    resetField: UseFormResetField<FieldValues>,
  ) => void;
}

export const ComptFileUploadField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptFileUploadFieldProps<TFieldValues, TName>,
) => {
  const field = (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <ComptReceiptUpload
          errors={props.errors}
          field={field as unknown as ControllerRenderProps}
          file={props.initialValue ?? null}
          resetField={props.resetField as unknown as UseFormResetField<FieldValues>}
          setError={props.setError as UseFormSetError<FieldValues>}
          clearError={props.clearError as UseFormClearErrors<FieldValues>}
          getValues={props.getValues as UseFormGetValues<FieldValues>}
          unregister={props.unregister as UseFormUnregister<FieldValues>}
          domain={props.domain}
          userId={props.userId}
          previewReceipt={props.previewReceipt}
          initialValue={props.initialValue}
          onKeyChange={props.onKeyChange}
          disabled={props.disabled}
        />
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      isFileUpload={true}
      className={props.comptFormFieldClasses}
      readOnly={props.readOnly}
    />
  );
};
