export const formatDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const isValidDate = (date: Date | null) => (date ? !isNaN(date.getTime()) : false);

export const dateToISODateString = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return date.toISOString().split('T')[0];
};

export const getUTCDateFromString = (dateString: string | Date | undefined | null) => {
  if (!dateString) {
    return '';
  }
  // Convert to UTC because Date() assumes the string is UTC, but will apply the local timezone
  // This can cause the dates to be displayed incorrectly, so we convert it back to UTC to get
  // the date that the string actually displayed.
  const convertedDate = new Date(dateString);
  return new Date(
    convertedDate.getUTCFullYear(),
    convertedDate.getUTCMonth(),
    convertedDate.getUTCDate(),
  );
};

export const formattedDate = (
  dateString: string | Date | undefined | null,
  formatOption: DATE_FORMAT_OPTION,
) => {
  const utcDate = getUTCDateFromString(dateString);
  return utcDate ? utcDate.toLocaleDateString('en-US', DateFormats[formatOption]) : '';
};

export enum DATE_FORMAT_OPTION {
  'month dd yyyy' = 'month dd yyyy',
  'mm/dd/yyyy' = 'mm/dd/yyyy',
}

const DateFormats: Record<DATE_FORMAT_OPTION, Intl.DateTimeFormatOptions> = {
  'month dd yyyy': {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  'mm/dd/yyyy': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
};
