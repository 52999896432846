import React from 'react';
import {getFileExtension} from '@compt/utils/image-helpers';
import {ComptMagnifier} from '../compt-magnifier/compt-magnifier';
import {twMerge} from 'tailwind-merge';

export type ComptUploadedReceiptProps = {
  receiptImage: string | null | undefined;
  fileType?: string | null | undefined;
  alt?: string;
  className?: string;
};

export const ComptUploadedReceipt = (props: ComptUploadedReceiptProps) => {
  const {receiptImage, fileType, alt} = props;

  if (!receiptImage) {
    return null;
  }

  const extension = getFileExtension(receiptImage);

  return (
    <div
      className={twMerge(
        `flex w-full h-full justify-center items-start rounded-xl
        bg-gray-100 max-h-full sm:max-h-[600px] overflow-auto`,
        props.className,
      )}
    >
      {extension === 'pdf' || fileType === 'application/pdf' ? (
        <iframe
          title="uploaded-receipt"
          className="preview-image w-full h-full"
          src={`${receiptImage}#toolbar=0`}
        />
      ) : (
        <ComptMagnifier
          src={`${receiptImage}`}
          className="m-auto"
          imgStyle={{
            objectFit: 'cover',
            width: '100%',
          }}
          alt={`${alt ?? 'preview-receipt'}`}
        />
      )}
    </div>
  );
};
